<template>
  <page-top
    :title="title"
    iconName="backward"
    notScroll
    isBackButton
    :isTrain="isTrain"
  >
    <div class="top-function">
      <template v-if="checkEditable">
        <button-main
          :text="buttonText"
          :disabled="disableSave"
          type="emphasis"
          @click="$emit('save')"
        />
        <text-box
          v-if="warnings.length > 0"
          class="top-warning-caution-text"
          isError
          :text="$t('recipe.connectionWarning.reason.' + warnings[0].warning)"
        />
      </template>
      <template v-else>
        <button-main :text="buttonText" type="disabled" />
        <div class="top-function-caution">
          <texts class="top-function-caution-text" color="caution" size="min">
            <div v-if="accountInfo.plan === 'free'">
              {{ $t('recipeEdit.caution.free.first') }}
              <router-link
                :to="{ name: 'changePlan' }"
                class="top-function-caution-link"
              >
                {{ $t('recipeEdit.caution.free.link') }}
              </router-link>
              {{ $t('recipeEdit.caution.free.last') }}
            </div>
            <div v-else>
              {{ $t('recipeEdit.caution.education.first') }}
              <router-link
                :to="{ name: 'changePlan' }"
                class="top-function-caution-link"
              >
                {{ $t('recipeEdit.caution.education.link') }}
              </router-link>
              {{ $t('recipeEdit.caution.education.last') }}
            </div>
          </texts>
        </div>
      </template>
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import ButtonMain from '@/components/atoms/button-main.vue'
import texts from '@/components/atoms/text'
import textBox from '@/components/atoms/text-box.vue'
import { checkExpiredEducation } from '@/lib/misc.js'

export default {
  components: {
    pageTop,
    ButtonMain,
    texts,
    textBox
  },
  props: {
    recipe: Object,
    recipeFrom: Object,
    accountInfo: Object,
    isTrain: Boolean,
    disableSave: Boolean,
    warnings: Array
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    title() {
      if (this.recipeFrom) {
        return this.$t('recipeEdit.title', { title: this.recipe?.name })
      } else {
        return this.$t('recipeEdit.titleNewRecipe')
      }
    },
    buttonText() {
      if (this.recipeFrom) {
        return this.$t('recipeEdit.saveButton')
      } else {
        return this.$t('recipeEdit.saveNewButton')
      }
    },
    checkEditable() {
      if (this.accountInfo.plan === 'free') return false
      if (checkExpiredEducation(this.accountInfo)) return false
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
.top-warning-caution-text {
  height: adjustVW(48);
  margin-left: $space-small;
}

.top-function {
  display: flex;
  align-items: center;
  &-caution {
    margin-left: $space-large;
    &-text {
      white-space: pre-line;
    }
    &-link {
      color: $text-caution;
      text-decoration: underline;
    }
  }
}
</style>
