<template>
  <div class="side-params">
    <transition name="toggle-parameter" mode="out-in">
      <div
        v-if="selectedNodes.length === 0"
        key="none"
        class="side-params-inner"
      >
        <div class="none">
          <div class="none-body">
            <images class="none-image" imageName="showBlock" />
            <texts
              class="none-text"
              :text="$t('recipe.sidebar.parameter.none')"
              color="gray"
              size="small"
            />
          </div>
        </div>
      </div>
      <div
        v-else-if="selectedNodes.length === 1"
        key="single"
        class="side-params-inner"
      >
        <parameter-wrap
          :parameters="selectedSingle"
          :edit="editting"
          :customblockDetail="customblockDetail"
          :loadingCustomblockDetail="loadingCustomblockDetail"
          :customblockVersions="customblockVersions"
          :customblockList="customblockList"
          @input="$emit('input', $event)"
          @remove-edge="$emit('remove-edge', $event)"
          @load-detail="$emit('load-detail', $event)"
          @version-change="$emit('version-change', $event)"
          @fetch-version-list="$emit('fetch-version-list', $event)"
        />
        <button
          v-if="editting"
          class="side-params-button"
          @click="deleteNodes()"
        >
          <text-with-icon
            class="side-params-button-inner"
            :text="$t('recipe.sidebar.deleteBlock')"
            iconName="delete"
          />
        </button>
      </div>
      <div
        v-else-if="selectedNodes.length >= 2"
        key="multi"
        class="side-params-inner"
      >
        <div class="side-params-multi">
          <texts
            class="side-params-multi-text"
            :text="$t('recipe.sidebar.selectMulti')"
            size="large"
          />
        </div>
        <button
          v-if="editting"
          class="side-params-button"
          @click="deleteNodes()"
        >
          <text-with-icon
            class="side-params-button-inner"
            :text="$t('recipe.sidebar.deleteBlock')"
            iconName="delete"
          />
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import Texts from '@/components/atoms/text.vue'
import images from '@/components/atoms/images.vue'
import parameterWrap from './sidebar/parameter-wrap.vue'
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    parameterWrap,
    Texts,
    images,
    textWithIcon
  },
  data() {
    return {
      showAutoFlowAlgorithm: true,
      showParamFlagList: []
    }
  },
  props: {
    selectedNodes: Array,
    editting: Boolean,

    customblockDetail: Object,
    loadingCustomblockDetail: Boolean,
    customblockVersions: Array,
    customblockList: Array
  },
  computed: {
    autoFlowAlgorithmList() {
      if (!this.selectedSingle) return []
      let isCurrentVersion = false
      const algoType = this.selectedSingle.node.params.type.value
      const initAlgorithm =
        this.selectedSingle.node.options.algorithm[algoType].value
      const algoParam = this.selectedSingle.node.params.algorithm
      let algorithmList = []
      if (!algoParam) {
        algorithmList = initAlgorithm
      } else if (!algoParam[algoType]) {
        const oldVersionAlgorithm =
          this.selectedSingle.node.params.algorithm.value // classification only
        algorithmList =
          algoType === 'classification' && oldVersionAlgorithm
            ? oldVersionAlgorithm
            : initAlgorithm
      } else if (Array.isArray(algoParam[algoType])) {
        // the recipe is inconsistency because it has too old params. initiaize the algorithms.
        algorithmList = initAlgorithm
      } else {
        isCurrentVersion = true
      }
      if (isCurrentVersion === false) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedSingle.node.params.algorithm = {
          [algoType]: {
            value: algorithmList
          }
        }
      }
      return this.selectedSingle.node.params.algorithm[algoType].value
    },
    activationOptions() {
      return [
        { value: 'relu', text: 'ReLU' },
        { value: 'ident', text: this.$t('activation.ident') },
        { value: 'sigmoid', text: 'Sigmoid' },
        { value: 'leaky_relu', text: 'Reaky ReLU' },
        { value: 'tanh', text: 'tanh' },
        { value: 'softplus', text: 'Softplus' },
        { value: 'softmax', text: 'Softmax' },
        { value: 'elu', text: 'ELU' },
        { value: 'selu', text: 'SELU' },
        { value: 'relu6', text: 'ReLU6' },
        { value: 'crelu', text: 'CReLU' },
        { value: 'swish', text: 'swish' }
      ]
    },
    selectedSingle() {
      if (this.selectedNodes.length === 1) {
        return this.selectedNodes[0]
      } else {
        return {}
      }
    },
    neighborNodes() {
      if (this.selectedSingle && this.selectedSingle.neighbors) {
        return Object.values(this.selectedSingle.neighbors)
      }
      return []
    },
    incomers() {
      if (this.selectedSingle && this.selectedSingle.incomeEdges) {
        return this.selectedSingle.incomeEdges
      }
      return []
    },
    outgoers() {
      if (this.selectedSingle && this.selectedSingle.outgoEdges) {
        return this.selectedSingle.outgoEdges
      }
      return []
    }
  },
  methods: {
    deleteEdge(id) {
      this.$emit('edge:remove', id)
    },
    deleteNodes() {
      for (const v of this.selectedNodes) {
        this.$emit('remove-node', v.node.id)
      }
    },
    setOptions(layerName, options) {
      const newOptions = []
      if (options) {
        options.forEach((v) => {
          newOptions.push({
            value: v.value,
            text: this.$t('recipe.param.' + layerName + '.' + v.text)
          })
        })
      }
      return newOptions
    }
  }
}
</script>

<style lang="scss" scoped>
.side-params {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .title {
    margin: 0 0 $space-small;
  }
  .desc {
    height: 100%;
    margin: 0 0 $space-small;
  }
  &-inner {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    height: 100%;
  }
  &-multi {
    display: flex;
    flex-shrink: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: $space-base;
    &-text {
      text-align: center;
      white-space: pre-line;
    }
  }
  &-button {
    display: flex;
    justify-content: flex-end;
    padding: $space-small 0;
    margin: 0 $space-small;
    border-top: $border-sub;
    transition: opacity $transition-base;
    &:hover {
      opacity: 0.5;
    }
    &-inner {
      width: auto;
    }
  }
}

.none {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
  }
  &-image {
    width: adjustVW(240);
    height: auto;
    margin: 0 0 $space-medium;
  }
  &-text {
    text-align: center;
    white-space: pre;
  }
}

.toggle-parameter-enter-active,
.toggle-parameter-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-parameter-enter,
.toggle-parameter-leave-to {
  opacity: 0;
  transform: translateX($space-medium);
  will-change: opacity, transform;
}
</style>
