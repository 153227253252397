<template>
  <div class="c-wrap">
    <sidebar-project-detail
      v-if="!notFoundProject || recipesLoading"
      v-bind="sidebar"
    />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="recipesLoading" />
          <page-not-found v-else-if="notFoundProject" />
          <recipe-list
            v-else
            :recipes="recipes"
            :accountInfo="accountInfo"
            @menu-click="iconMenuClick"
            @get-recipe-template="getRecipeTemplate()"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu />
    <recipe-list-popup
      v-bind="popup"
      :recipeLayers="recipeLayers"
      :recipeTemplates="recipeTemplates"
      :loadingRecipeTemplates="loadingRecipeTemplates"
      :disableClick="disableClick"
      @close-modal="closePopup"
      @delete-recipes="deleteRecipe"
      @save-recipe-template="saveRecipeTemplate($event)"
      @add-to-project="applyProjectAdd"
    />
  </div>
</template>
<script>
import setMountedTimer from '@/mixin/set-mounted-timer'
import loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import TransitionPage from '@/components/molecules/transition-page.vue'
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import recipeList from '@/components/organisms/recipe-list/recipe-list-main.vue'
import recipeListPopup from '@/components/organisms/recipe-list/recipe-list-popup.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import pageNotFound from '@/components/organisms/page-not-found'

import { mapActions, mapGetters } from 'vuex'
import { splitFullId } from '@/lib/misc.js'
import { recipeValidator } from '@/lib/validator/recipe.js'

export default {
  components: {
    loading,
    loadingProcessing,
    TransitionPage,
    sidebarProjectDetail,
    Sidebar,
    headerTabs,
    hoverMenu,
    recipeList,
    recipeListPopup,
    TransitionToggleContents,
    pageNotFound
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      vm.loadProjectRecipes()
      await vm.fetchAccountInfo()
    })
  },
  computed: {
    ...mapGetters('recipes', ['recipeTemplates', 'loadingRecipeTemplates', 'recipeLayers']),
    ...mapGetters('recipes', {
      allRecipes: 'recipes',
      allRecipesLoading: 'recipeLoading'
    }),
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('project', ['projectList', 'loadingProjectList']),

    projectId() {
      if (this.$route.params.projectId) {
        return parseInt(this.$route.params.projectId)
      }
      return null
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    recipes() {
      if (this.projectId) {
        return this.projectRecipes
      } else {
        return this.allRecipes
      }
    },
    recipesLoading() {
      if (this.projectId) {
        return this.projectRecipesLoading || this.loadingProjectList
      } else {
        return this.allRecipesLoading
      }
    },
    disableClick() {
      return this.submitDeleting || this.submitOther
    }
  },
  methods: {
    ...mapActions('recipes', [
      'loadTemplate',
      'deleteRecipes',
      'fetchLayers',
      'fetchTemplateList',
      'loadRecipeList',
      'setEditRecipe'
    ]),
    ...mapActions('project', ['projectAddRecipe']),
    ...mapActions('auth', ['fetchAccountInfo']),

    async loadProjectRecipes() {
      if (this.projectId) {
        this.projectRecipesLoading = true
        try {
          const response = await this.$sendMessageAndReceive({
            action: 'getRecipeList',
            projectId: this.projectId
          })
          if (response.status !== 'error') {
            const obj = {}
            response.list.forEach((item) => {
              const fullId = item.id + '-' + item.accountId
              item.fullId = fullId
              obj[fullId] = item
            })
            this.projectRecipes = obj
          } else {
            throw response
          }
        } catch (e) {
          console.log(e)
        } finally {
          this.projectRecipesLoading = false
        }
      } else {
        this.loadRecipeList()
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    showPopup(e) {
      this.popup.showPopup.push(e)
    },
    async loadDetail(fullId) {
      const [recipeId, recipeAccountId] = splitFullId(fullId)
      const res = await this.$sendMessageAndReceive({
        action: 'getRecipeDetail',
        recipe_id: recipeId,
        recipe_account_id: recipeAccountId
      })
      return res.detail
    },
    async deleteRecipe(targets) {
      this.submitDeleting = true
      const recipes = targets.map((x) => ({ id: x.id, accountId: x.accountId }))
      try {
        await this.deleteRecipes(recipes)
        if (this.projectId) {
          await this.loadProjectRecipes()
        }
      } finally {
        this.submitDeleting = false
      }
      this.closePopup('deleteRecipe')
    },
    async iconMenuClick({ name, checked }) {
      switch (name) {
        case 'copyNewRecipe': {
          const recipeFullId = checked[0]
          const recipeData = await this.loadDetail(recipeFullId)

          this.setEditRecipe({
            recipe: recipeData,
            from: null
          })

          this.$router.push({
            name: 'recipeEdit'
          })
          break
        }
        case 'delete':
          this.popup.deleteRecipe = {
            recipes: checked.map((id) => this.recipes[id])
          }
          this.showPopup('deleteRecipe')
          break
        case 'project':
          this.popup.intoProject = {
            targets: checked.map((id) => this.recipes[id])
          }
          this.showPopup('intoProject')
          break
        case 'project-create':
          this.popup.intoProject = {
            targets: checked.map((id) => this.recipes[id]),
            newProject: true
          }
          this.showPopup('intoProject')
          break
      }
    },
    async applyProjectAdd(target) {
      this.submitOther = true
      try {
        if (target.project.new) {
          await this.projectAddRecipe({
            targets: target.targets,
            project: target.project
          })
          this.$router.push({
            name: 'projectList'
          })
        } else {
          await this.projectAddRecipe({
            targets: target.targets,
            projectId: target.project.id
          })
        }
        await this.loadProjectRecipes()
      } finally {
        this.submitOther = false
      }
      this.closePopup('intoProject')
    },
    async getRecipeTemplate() {
      this.showPopup('getRecipeTemplate')
      await this.fetchLayers()
      await this.fetchTemplateList()
    },
    saveRecipeTemplate: async function (id) {
      this.submitOther = true
      const targetRecipeTemplate = this.recipeTemplates[id]
      const recipeTemplateValidate = recipeValidator(
        this.recipes,
        targetRecipeTemplate
      )
      if (!recipeTemplateValidate?.duplicate) {
        targetRecipeTemplate.body.info.name =
          recipeTemplateValidate?.suggests[0]
      }
      try {
        const res = await this.loadTemplate({
          type: 'recipe',
          id: targetRecipeTemplate.id,
          name: targetRecipeTemplate.body.info.name,
          projectId: this.projectId
        })

        if (res.status !== 'error') {
          await this.loadProjectRecipes()
        }
      } finally {
        this.submitOther = false
      }
      this.closePopup('getRecipeTemplate')
    }
  },
  data() {
    return {
      projectRecipes: null,
      projectRecipesLoading: true,
      headerTabs: {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 1
      },
      sidebar: {
        // サイドバーに表示する情報
        project: {
          name: '退職予測',
          description:
            'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
        },
        activeLink: 'recipe'
      },
      popup: {
        showPopup: [],
        deleteRecipe: null,
        intoProject: {
          targets: []
        }
      },
      detailTemplateLoading: false,
      submitDeleting: false,
      submitOther: false
    }
  }
}
</script>
<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  background: $background;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background: $background-sub;
}
</style>
