<template>
  <div class="radio-list">
    <div class="radio-item">
      <radio-button-base
        v-model="selected"
        text="__number__"
        :name="name"
        :checked="selected === '__number__'"
        noText
      />
      <input-box
        ref="inputBox"
        v-tooltip="checkSelectInputNull"
        class="radio-input"
        type="number"
        :value="numValue"
        :min="min"
        :max="max"
        :step="step"
        forceStep
        isGray
        @input="updateNumInputValue"
        @change="updateNumValue"
        @blur="resetTips"
      />
    </div>
    <div v-for="(item, index) in items" :key="index" class="radio-item">
      <radio-button-base
        v-model="selected"
        class="radio-inner"
        :text="item"
        :displayName="checkTranslatedName(item, index)"
        :name="name"
        :checked="item === selected"
        :tips="tooltipList[index]"
      />
    </div>
  </div>
</template>

<script>
import radioButtonBase from '@/components/atoms/radio-button-base'
import inputBox from '@/components/molecules/input-box'

export default {
  components: {
    radioButtonBase,
    inputBox
  },
  props: {
    /** インプットボックス以外の選択項目 */
    items: {
      type: Array,
      default: () => []
    },
    /** インプットボックスに入るデフォルトの値 */
    defaultNumValue: {
      type: Number,
      default: 0
    },
    /** 初期選択項目 */
    value: {
      required: true
    },
    /** 設定していないため使用不可、使わないで下さい */
    disabled: {
      type: Boolean,
      default: false
    },
    /** ラジオボタンのname */
    name: String,
    /** inputの最小値 */
    min: Number,
    /** inputの最大値 */
    max: Number,
    /** 数値入力欄のstep */
    step: Number,
    /** TODO 本来はitemsに組み合わせて渡すべきだが挙動が不明なため、別のpropsとして渡している、itemsをi18nで変換した名前 */
    translatedNames: {
      type: Array,
      default: () => []
    },
    /** TODO 本来はitemsに組み合わせて渡すべきだが挙動が不明なため、別のpropsとして渡している、tooltipをi18nで変換した名前 */
    tooltipList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const v = this.value
    let sel = '__number__'
    let nv = this.defaultNumValue
    if (this.items.includes(v)) {
      sel = v
    } else {
      if (typeof v === 'number') {
        nv = v
      }
    }
    return {
      /** ラジオボタンで選択中の項目 */
      selected: sel,
      /** インプットボックスで入力した値 */
      numValue: nv,
      /** 数値入力欄が最大値を超える場合 */
      isMax: false,
      /** 数値入力欄が最小値を超える場合 */
      isMin: false,
      /** 必須項目が未入力 */
      isRequired: false
    }
  },
  methods: {
    resetTips() {
      this.isMax = false
      this.isMin = false
      this.isRequired = false
    },
    updateNumInputValue(val) {
      this.isMax = false
      this.isMin = false
      this.isRequired = false
      if (this.max != null && val >= this.max) {
        this.isMax = true
      } else if (this.min != null && this.min >= val) {
        this.isMin = true
      } else if (isNaN(val)) {
        this.isRequired = true
      }
    },
    updateNumValue(val) {
      this.isMax = false
      this.isMin = false
      this.isRequired = false
      let value = val
      if (this.max != null && value >= this.max) {
        value = this.max
        this.isMax = true
      } else if (this.min != null && this.min >= value) {
        value = this.min
        this.isMin = true
      } else if (isNaN(value)) {
        value = this.defaultNumValue
        this.isRequired = true
      }
      if (this.value === value || this.selected !== '__number__') {
        this.$refs.inputBox.updateValueByParent(value)
      }
      this.numValue = value
    },
    checkTranslatedName(item, index) {
      if (this.translatedNames.length === 0) return item
      return this.translatedNames[index]
    }
  },
  computed: {
    _value() {
      if (this.selected === '__number__') {
        return this.numValue
      }
      return this.selected
    },
    checkSelectInputNull() {
      if (this.isMax) {
        return {
          content: this.$t('recipe.editTips.max', { max: this.max }),
          show: this.isMax,
          trigger: 'manual'
        }
      } else if (this.isMin) {
        return {
          content: this.$t('recipe.editTips.min', { min: this.min }),
          show: this.isMin,
          trigger: 'manual'
        }
      } else if (this.isRequired) {
        return {
          content: this.$t('recipe.editTips.required', {
            defaultValue: this.defaultNumValue
          }),
          show: this.isRequired,
          trigger: 'manual'
        }
      } else {
        return {
          content: ''
        }
      }
    }
  },
  watch: {
    selected(newval, old) {
      this.$emit('input', this._value)
    },
    numValue(newval, old) {
      if (this.selected === '__number__') {
        this.$emit('input', this._value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.radio {
  &-item {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 $space-small;
  }
  &-inner {
    width: 100%;
  }
  &-input {
    width: 100%;
    height: adjustVW(40);
  }
}
</style>
