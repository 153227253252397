<template>
  <div class="list">
    <div v-for="(block, index) in filteredBlocks" :key="index" class="item">
      <block :block="block" @block-click="$emit('block-click', $event)" />
    </div>
  </div>
</template>

<script>
import block from './block'
export default {
  components: {
    block
  },
  props: {
    /** ブロック一覧 */
    filteredBlocks: Array
  }
}
</script>

<style lang="scss" scoped>
.list {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: adjustVW(2);
  @include scrollbar;
}
.item {
  width: 100%;
  margin: 0 0 $space-small;
  &:last-of-type {
    margin: 0;
  }
}
</style>
