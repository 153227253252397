<!-- 選択したブロックタイプの一覧 -->
<template>
  <div class="body">
    <button class="title" @click="$emit('reset-select')">
      <icons class="icon" iconName="backward" size="large" />
      <texts :text="$t('recipe.layerType.' + selectedType)" size="large" />
    </button>
    <div class="block-list">
      <marketplace-blank
        v-if="filteredBlocks == null && selectedType == 'marketplace'"
      />
      <block-list
        v-else
        :filteredBlocks="filteredBlocks"
        @block-click="$emit('block-click', $event)"
      />
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import texts from '@/components/atoms/text'
import blockList from '@/components/molecules/recipe/block-list.vue'
import marketplaceBlank from '@/components/molecules/recipe/marketplace-blank.vue'

export default {
  components: {
    icons,
    texts,
    blockList,
    marketplaceBlank
  },
  props: {
    /** ブロックタイプでフィルタリングされたブロック一覧 */
    filteredBlocks: Array,
    /** 選択したブロックタイプ */
    selectedType: String
  }
}
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: $space-small $space-base $space-small $space-small;
}
.title {
  display: flex;
  align-items: center;
  margin: 0 0 $space-small;
}
.icon {
  margin: 0 $space-base 0 0;
}
.block-list {
  overflow: hidden;
  height: 100%;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $space-text $space-small;
  margin: 0 0 $space-small;
  border-bottom: $border-main;
  &:last-of-type {
    margin: 0;
  }
}
</style>
