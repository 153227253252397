<template>
  <div class="c-wrap">
    <recipe-edit
      :sidebar="sidebar"
      :projectInfo="projectInfo"
      :progressTraining="progressTraining"
      :headerTabs="headerTabs"
      :popup="popup"
      :recipe="recipe"
      :recipeLayers="recipeLayers"
      :recipeFrom="editRecipeFrom"
      :loading="loading"
      :accountInfo="accountInfo"
      :notFound="notFoundProject"
      :disableClick="disableClick"
      :incorrectOrder="incorrectOrder"
      :customblockList="customblockList"
      :loadingCutomblockList="loadingCutomblockList"
      :customblockDetail="customblockDetail"
      :loadingCustomblockDetail="loadingCustomblockDetail"
      :customblockVersions="customblockVersions"
      :customblockVersionParams="customblockVersionParams"
      @close-modal="closePopup"
      @input-create-form="inputCreateForm($event)"
      @save-recipe="clickSaveRecipe"
      @save-recipe-confirm="saveRecipe"
      @not-prevent="notPrevent()"
      @drop-customblock="dropCustomblock"
      @fetch-version-list="versionSelectOpen"
      @load-detail="fetchCustomblockDetail"
      @fetch-customblock-detail="fetchCustomBlockVersionParams"
    />
    <prevent-leave
      ref="preventLeave"
      v-model="isPrevent"
      :cautionTexts="$t('recipeEdit.notSaveLeave')"
    />
  </div>
</template>

<script>
import recipeEdit from '@/components/templates/train/recipe-edit'
import preventLeave from '@/components/molecules/prevent-leave'

import { mapActions, mapGetters } from 'vuex'
import { checkTrainingOrder } from '@/lib/progress-training'
import { recipeValidator } from '@/lib/validator/recipe.js'
import { checkReasons } from '@/lib/checkReasons.js'
import { checkExpiredEducation } from '@/lib/misc.js'
import { initCustomblockParams } from '@/lib/customblock/initBlock'
import setMountedTimer from '@/mixin/set-mounted-timer'

export default {
  components: {
    recipeEdit,
    preventLeave
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      vm.fetchLayers()

      function f() {
        if (vm.recipeLayers) {
          vm.loadDetail()
        } else {
          setTimeout(f, 500)
        }
      }

      f()

      if (vm.projectId) vm.loadRecipeList(vm.projectId)
      else vm.loadRecipeList()

      await vm.setModelSettingInfo(parseInt(to.params.projectId))
      vm.incorrectOrder = !checkTrainingOrder({
        projectId: vm.projectId,
        stage: 'recipe',
        progressList: vm.modelSettingList
      })

      if (vm.incorrectOrder) {
        vm.showPopup('preventTrainingStatus')
      }
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.loadDetail()
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isPrevent && this.checkEditable) {
      const result = await this.$refs.preventLeave.$confirm()
      if (result) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  methods: {
    ...mapActions('recipes', [
      'addRecipe',
      'loadRecipeList',
      'setEditRecipe',
      'fetchLayers',
      'updateRecipe',
      'getRecipeTypeBeforeAdd'
    ]),
    ...mapActions('models', ['getProgressTraining', 'setProgressTraining']),

    async loadDetail() {
      if (this.editRecipe) {
        this.recipe = this.editRecipe
      } else {
        const layersInfo = {
          inputData: { id: 0, position: { x: 150, y: 100 } },
          acc: { id: 1, position: { x: 350, y: 200 } }
        }
        const initialLayers = []
        for (const k in layersInfo) {
          const l = JSON.parse(JSON.stringify(this.recipeLayers[k]))
          l.graph = {}
          l.graph.position = layersInfo[k].position
          l.id = layersInfo[k].id
          initialLayers.push(l)
        }

        const body = this.progressTraining?.tmpRecipe ?? {
          edges: [],
          layers: initialLayers,
          info: {
            name: '',
            description: '',
            graph: {
              pan: { x: 0, y: 0 },
              zoom: 1
            }
          }
        }
        this.recipe = {
          name: '',
          description: '',
          body: body
        }
      }
      this.detailLoading = false
    },
    showPopup(e) {
      if (!this.popup.showPopup.find((list) => list === e)) {
        this.popup.showPopup.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    async clickSaveRecipe(e) {
      try {
        this.checkValidLoading = true
        if (this.progressTraining && !this.datasetLoading) {
          if (!this.trainingDataset) {
            this.loadingDatasetDetail = true
          }
          this.saveRecipeType = await this.recipeType(e)
          this.recipe.body = e
          this.validColumnCheck = await this.validPredictColumns()
          this.popup.selectRecipeErrors = checkReasons.bind(this)()
          if (this.popup.selectRecipeErrors.length > 0) {
            this.setProgressTraining({
              item: e,
              setType: 'tmpRecipe',
              projectId: this.projectId
            })

            this.showPopup('selectRecipeError')
            this.loadingDatasetDetail = false
            return
          }
        }
      } finally {
        this.checkValidLoading = false
      }
      this.popup.saveRecipe = e
      this.showPopup('saveRecipe')
    },
    async saveRecipe(recipeBody) {
      this.submitSave = true
      let res
      try {
        if (this.editRecipeFrom) {
          res = await this.updateRecipe({
            accountId: this.editRecipeFrom.accountId,
            projectId: this.projectId,
            recipe: recipeBody,
            recipeId: this.editRecipeFrom.id
          })
        } else {
          res = await this.addRecipe({
            projectId: this.projectId,
            recipe: recipeBody
          })
        }
      } finally {
        this.submitDeleting = false
      }
      if (res.status !== 'error') {
        await this.loadRecipeList()
        this.closePopup('saveRecipe')
        this.isPrevent = false
        if (this.progressTraining && !this.datasetLoading) {
          this.setProgressTraining({
            item: res.detail.id,
            setType: 'recipe',
            projectId: this.projectId
          })
          this.setProgressTraining({
            item: null,
            setType: 'tmpRecipe',
            projectId: this.projectId
          })

          this.$router.replace({
            name: 'training',
            params: { projectId: this.projectId }
          })
        } else {
          this.$router.replace({
            name: 'trainRecipeDetail',
            params: { id: res.detail.id + '-' + res.detail.accountId }
          })
        }
      }
    },
    inputCreateForm: function (obj) {
      const { type, form } = obj
      const targetRecipeList = JSON.parse(JSON.stringify(this.recipeList))
      if (this.editRecipeFrom) {
        delete targetRecipeList[
          this.editRecipeFrom.id + '-' + this.editRecipeFrom.accountId
        ]
      }

      if (type === 'recipe') {
        this.popup.createInfo.recipeFormValidate = recipeValidator(
          targetRecipeList,
          form
        )
      }
    },
    notPrevent() {
      this.isPrevent = false
    },
    selectedLearningDataType() {
      if (!this.trainingDataset) return null
      let dataType = null
      if (this.trainingDataset.nRows > 0) {
        if (dataType != null) return null
        dataType = 'figures'
      }
      if (this.trainingDataset.nImages > 0) {
        if (dataType != null) return null
        dataType = 'images'
      }
      if (this.trainingDataset.nTexts > 0) {
        if (dataType != null) return null
        dataType = 'texts'
      }
      return dataType
    },
    setModelSettingInfo: async function (projectId) {
      if (projectId) {
        this.progressTraining = await this.getProgressTraining({
          projectId
        })
      } else {
        this.progressTraining = null
      }
    },
    inputDataType() {
      if (!this.recipe) return null
      const input = this.recipe.body.layers.find((x) => x.name === 'inputData')
      return input?.params?.dataType?.value
    },
    autoflowNoMultivalue() {
      if (!this.recipe) return null
      return this.recipe.body.layers
        .filter((x) => x.name === 'AutoFlow')
        .some(
          (input) =>
            input?.params?.classMetrics?.value !== 'default' &&
            input?.params?.classMetrics?.value !== 'accuracy'
        )
    },
    async recipeType(recipe) {
      const res = await this.getRecipeTypeBeforeAdd({ recipe: recipe })
      return res.recipeType
    },
    async validPredictColumns() {
      const datasetType = this.selectedLearningDataType()
      if (datasetType === 'figures') {
        const req = {
          action: 'validPredictColumns',
          data_id: this.datasetId,
          data_account_id: this.datasetList[this.datasetId].accountId,
          no_multi_value: this.autoflowNoMultivalue()
        }
        try {
          const res = await this.$sendMessageAndReceive(req)
          if (res.status === 'error') throw new Error(res)

          return this.progressTraining.targetColumn.map((item) => {
            return {
              ...res.predict_columns[item],
              columnName: item
            }
          })
        } catch (error) {
          throw new Error(error)
        }
      } else {
        return { valid: true }
      }
    },
    async fetchCustomblockDetail({ customBlockId, version }) {
      if (this.customblockDetail?.customblock_id === customBlockId) return
      this.loadingCustomblockDetail = true
      try {
        const res = await this.$sendMessageAndReceive({
          action: 'getCustomBlockDetail',
          customBlockId,
          version
        })
        this.customblockDetail = res.result
      } finally {
        this.loadingCustomblockDetail = false
      }
    },
    async dropCustomblock({ customBlockId, version, callback }) {
      const res = await this.$sendMessageAndReceive({
        action: 'getCustomBlockDetail',
        customBlockId,
        version
      })
      const result = res.result
      // graph.vueの処理を再開する
      callback(result)
    },
    async versionSelectOpen({ customBlockId }) {
      const res = await this.$sendMessageAndReceive({
        action: 'getCustomBlockHistory',
        customBlockId
      })
      this.customblockVersions = res.result.map((item) => ({
        name: item.version,
        value: item.version,
        info: item.release_note
      }))
    },
    async fetchCustomBlockVersionParams({
      blockId,
      customBlockId,
      newVersion
    }) {
      const res = await this.$sendMessageAndReceive({
        action: 'getCustomBlockDetail',
        customBlockId,
        version: newVersion
      })

      const newTemplateParams = initCustomblockParams(res.result.params)

      this.customblockVersionParams = { blockId, newVersion, newTemplateParams }
    }
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('datasets', ['datasetLoading']),
    ...mapGetters('project', ['loadingProjectList', 'projectList', 'projectLoading']),
    ...mapGetters('recipes', ['editRecipe', 'editRecipeFrom', 'recipeLayers']),
    ...mapGetters('recipes', {
      allRecipeList: 'recipes'
    }),
    ...mapGetters('models', ['modelSettingList']),
    ...mapGetters('customblock', {
      customblockList: 'customblockList',
      loadingCutomblockList: 'loading'
    }),
    ...mapGetters('trainingDataset', ['trainingDataset']),

    datasetId() {
      if (!this.progressTraining) return null
      if (this.progressTraining.preprocessingDatasetId) {
        return this.progressTraining.preprocessingDatasetId
      } else {
        return this.progressTraining.datasetId
      }
    },
    projectId() {
      if (this.$route.params.projectId) {
        return parseInt(this.$route.params.projectId)
      }
      return null
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    projectInfo() {
      if (this.projectId) {
        return this.projectList[this.projectId]
      }
      return null
    },
    recipeList() {
      if (this.projectId) return this.allRecipeList
      else return []
    },
    sidebar() {
      return {
        // サイドバーに表示する情報
        activeLink: 'recipeSetting',
        status: 'create'
      }
    },
    loading() {
      return (
        this.detailLoading || this.loadingProjectList || this.checkValidLoading
      )
    },
    disableClick() {
      return this.submitSave
    },
    checkEditable() {
      if (this.accountInfo.plan === 'free') return false
      if (checkExpiredEducation(this.accountInfo)) return false
      return true
    },
    datasetList() {
      const result = {}
      this.projectList[this.projectId]?.listData?.forEach((item) => {
        result[item.id] = item
      })
      return result
    }
  },
  data() {
    return {
      isTrain: true,
      headerTabs: {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 1
      },
      recipe: null,
      detailLoading: true,
      checkValidLoading: false,
      popup: {
        createInfo: {
          recipeFormValidate: {
            duplicate: null
          }
        },
        showPopup: [],
        selectRecipeErrors: null,
        saveRecipe: null
      },
      isPrevent: true,
      submitSave: false,
      saveRecipeType: null,
      validColumnCheck: [],
      incorrectOrder: null,
      progressTraining: null,
      customblockDetail: null,
      loadingCustomblockDetail: false,

      customblockVersions: [],
      customblockVersionParams: {}
    }
  }
}
</script>
