<template>
  <div class="wrap">
    <transition name="toggle-parameter" mode="out-in">
      <div v-if="loadingCustomblockDetail" key="loading" class="loading">
        <loading-icon />
      </div>
      <div
        v-else-if="Object.keys(parameters).length"
        key="contents"
        class="body"
      >
        <div class="main">
          <div class="title">
            <texts :text="$t('recipe.sidebar.parameter.title')" size="large" />
          </div>
          <text-with-title
            class="name"
            :title="$t('recipe.sidebar.parameter.name')"
            :text="getLayerName(parameters.node)"
          />
        </div>
        <div v-if="parameters.node.name === 'customblock'" class="main">
          <template v-if="edit">
            <texts
              class="c-textblock-title"
              size="small"
              color="gray"
              :text="$t('recipe.sidebar.parameter.customblockVersion')"
            />
            <div class="c-textblock-wrap">
              <select-box
                :firstSelectItem="{
                  name: parameters.node.customblockVersion,
                  value: parameters.node.customblockVersion
                }"
                :items="customblockVersions"
                isGray
                showInfo
                @input="versionSelectInput"
                @select-box-open="versionSelectOpen"
              />
            </div>
          </template>
          <text-with-title
            v-else
            :title="$t('recipe.sidebar.parameter.customblockVersion')"
            :text="parameters.node.customblockVersion"
          />
        </div>
        <transition name="toggle-parameter" mode="out-in">
          <!-- データ入り口 -->
          <div
            v-if="parameters.node.name === 'inputData'"
            key="input"
            class="input"
          >
            <div
              v-for="(param, key) in parameters.node.params"
              :key="key"
              class="item"
            >
              <transition name="toggle-parameter" mode="out-in">
                <parameter
                  v-if="key === 'dataType'"
                  :layerName="parameters.node.name"
                  :name="key"
                  :edit="edit"
                  :type="param.type"
                  :value="param.value"
                  :extra_selections="param.extra_selections"
                  :options="setOptions(parameters.node.name, param.options)"
                  :selectChange="changeInputDataType"
                  :step="param.step"
                  :force-step="param.forceStep"
                  :min="param.min"
                  :max="param.max"
                  :defaultValue="param.defaultValue"
                  :defaultNumValue="param.defaultNumValue"
                  @input="
                    $emit('input', {
                      param: $event,
                      paramName: key,
                      blockId: parameters.node.id
                    })
                  "
                />
                <!-- データ入り口が数値の場合 -->
                <parameter
                  v-else-if="
                    parameters.node.params.dataType.value === 'figures' &&
                      (key === 'dataShuffle' || key === 'fixSplit')
                  "
                  :layerName="parameters.node.name"
                  :name="key"
                  :edit="edit"
                  :type="param.type"
                  :value="param.value"
                  :extra_selections="param.extra_selections"
                  :options="param.options"
                  :step="param.step"
                  :force-step="param.forceStep"
                  :min="param.min"
                  :max="param.max"
                  :defaultValue="param.defaultValue"
                  :defaultNumValue="param.defaultNumValue"
                  @input="
                    $emit('input', {
                      param: $event,
                      paramName: key,
                      blockId: parameters.node.id
                    })
                  "
                />
                <!-- データ入り口が画像の場合 -->
                <parameter
                  v-else-if="
                    parameters.node.params.dataType.value === 'images' &&
                      key !== 'dataShuffle' &&
                      key !== 'fixSplit'
                  "
                  :layerName="parameters.node.name"
                  :name="key"
                  :edit="edit"
                  :type="param.type"
                  :value="param.value"
                  :extra_selections="param.extra_selections"
                  :options="param.options"
                  :step="param.step"
                  :force-step="param.forceStep"
                  :min="param.min"
                  :max="param.max"
                  :defaultValue="param.defaultValue"
                  :defaultNumValue="param.defaultNumValue"
                  @input="
                    $emit('input', {
                      param: $event,
                      paramName: key,
                      blockId: parameters.node.id
                    })
                  "
                />
              </transition>
            </div>
          </div>
          <!-- オートフローの場合 -->
          <div
            v-else-if="parameters.node.name === 'AutoFlow'"
            key="algo"
            class="algo-wrap"
          >
            <template v-for="(param, key, index) in parameters.node.params">
              <div v-if="key === 'type'" :key="key + index" class="item">
                <parameter
                  :layerName="parameters.node.name"
                  :name="key"
                  :edit="edit"
                  :type="param.type"
                  :value="param.value"
                  :extra_selections="param.extra_selections"
                  :options="setOptions(parameters.node.name, param.options)"
                  :selectChange="changeInputDataType"
                  :step="param.step"
                  :force-step="param.forceStep"
                  :min="param.min"
                  :max="param.max"
                  :defaultValue="param.defaultValue"
                  :defaultNumValue="param.defaultNumValue"
                  @input="
                    $emit('input', {
                      param: $event,
                      paramName: key,
                      blockId: parameters.node.id
                    })
                  "
                />
              </div>
              <!-- 分類の場合の指標一覧 -->
              <div
                v-else-if="
                  key === 'classMetrics' &&
                    parameters.node.params.type.value === 'classification'
                "
                :key="key + index"
                class="item"
              >
                <parameter
                  :layerName="parameters.node.name"
                  :name="key"
                  :edit="edit"
                  :type="param.type"
                  :value="param.value"
                  :extra_selections="param.extra_selections"
                  :options="setOptions(parameters.node.name, param.options)"
                  :selectChange="changeInputDataType"
                  :step="param.step"
                  :force-step="param.forceStep"
                  :min="param.min"
                  :max="param.max"
                  :defaultValue="param.defaultValue"
                  :defaultNumValue="param.defaultNumValue"
                  scrollBar
                  :scrollSize="150"
                  @input="
                    $emit('input', {
                      param: $event,
                      paramName: key,
                      blockId: parameters.node.id
                    })
                  "
                />
              </div>
              <div
                v-else-if="key === 'nTrials' || key === 'featureSelectDegree'"
                :key="key + index"
                class="item"
              >
                <parameter
                  :layerName="parameters.node.name"
                  :name="key"
                  :edit="edit"
                  :type="param.type"
                  :value="param.value"
                  :extra_selections="param.extra_selections"
                  :options="param.options"
                  :step="param.step"
                  :force-step="param.forceStep"
                  :min="param.min"
                  :max="param.max"
                  :defaultValue="param.defaultValue"
                  :defaultNumValue="param.defaultNumValue"
                  @input="
                    $emit('input', {
                      param: $event,
                      paramName: key,
                      blockId: parameters.node.id
                    })
                  "
                />
              </div>
              <!-- 分類の場合の、クロスバリデーションの設定 -->
              <div
                v-else-if="
                  key === 'classSplitter' &&
                    parameters.node.params.type.value === 'classification'
                "
                :key="key + index"
                class="item"
              >
                <parameter
                  :layerName="parameters.node.name"
                  :name="key"
                  :edit="edit"
                  :type="param.type"
                  :value="param.value"
                  :extra_selections="param.extra_selections"
                  :options="setOptions(parameters.node.name, param.options)"
                  :step="param.step"
                  :force-step="param.forceStep"
                  :min="param.min"
                  :max="param.max"
                  :defaultValue="param.defaultValue"
                  :defaultNumValue="param.defaultNumValue"
                  scrollBar
                  :scrollSize="150"
                  @input="
                    $emit('input', {
                      param: $event,
                      paramName: key,
                      blockId: parameters.node.id
                    })
                  "
                />
              </div>
              <!-- 回帰の場合の、クロスバリデーションの設定 -->
              <div
                v-else-if="
                  key === 'regSplitter' &&
                    parameters.node.params.type.value === 'regression'
                "
                :key="key + index"
                class="item"
              >
                <parameter
                  :layerName="parameters.node.name"
                  :name="key"
                  :edit="edit"
                  :type="param.type"
                  :value="param.value"
                  :extra_selections="param.extra_selections"
                  :options="setOptions(parameters.node.name, param.options)"
                  :step="param.step"
                  :force-step="param.forceStep"
                  :min="param.min"
                  :max="param.max"
                  :defaultValue="param.defaultValue"
                  :defaultNumValue="param.defaultNumValue"
                  scrollBar
                  :scrollSize="150"
                  @input="
                    $emit('input', {
                      param: $event,
                      paramName: key,
                      blockId: parameters.node.id
                    })
                  "
                />
              </div>
              <div
                v-else-if="key === 'nCvSplits'"
                :key="key + index"
                class="item"
              >
                <parameter
                  :layerName="parameters.node.name"
                  :name="key"
                  :edit="edit"
                  :type="param.type"
                  :value="param.value"
                  :extra_selections="param.extra_selections"
                  :options="setOptions(parameters.node.name, param.options)"
                  :step="param.step"
                  :force-step="param.forceStep"
                  :min="param.min"
                  :max="param.max"
                  :defaultValue="param.defaultValue"
                  :defaultNumValue="param.defaultNumValue"
                  @input="
                    $emit('input', {
                      param: $event,
                      paramName: key,
                      blockId: parameters.node.id
                    })
                  "
                />
              </div>
              <!-- オートフローのアルゴリズム一覧場合 -->
              <div
                v-else-if="key === 'algorithm'"
                :key="key + index"
                class="algo-block"
              >
                <button
                  class="algo-button"
                  :class="{ 'algo-button-toggle': showAutoFlowAlgorithm }"
                  @click="showAutoFlowAlgorithm = !showAutoFlowAlgorithm"
                >
                  <icons class="algo-button-icon" iconName="toggle" />
                  <texts :text="$t('recipe.autoflow.' + key)" />
                </button>
                <transition name="toggle-algo" mode="out-in">
                  <div v-if="showAutoFlowAlgorithm" class="algo-inner">
                    <div
                      v-for="(algo, index) in autoFlowAlgorithmList"
                      :key="index"
                      class="algo-item"
                    >
                      <button
                        class="algo-item-button"
                        @click="showArgoDetail(index)"
                      >
                        <div class="algo-item-checkbox">
                          <checkbox-base
                            v-model="algo.active"
                            :checked="algo.active"
                            :isDisabled="!edit"
                          />
                          <texts
                            class="algo-item-checkbox-text"
                            :text="$t('recipe.layerNames.' + algo.name)"
                            size="small"
                          />
                        </div>
                        <div
                          class="algo-item-icon"
                          :class="{
                            'algo-item-icon-toggle':
                              showParamFlagList.indexOf(index) > -1
                          }"
                        >
                          <icons iconName="toggle" />
                        </div>
                      </button>
                      <transition
                        name="toggle-algo"
                        mode="out-in"
                        @enter="enter($refs.target[index])"
                        @leave="leave($refs.target[index])"
                      >
                        <div
                          v-show="showParamFlagList.indexOf(index) > -1"
                          ref="target"
                          class="algo-detail"
                        >
                          <div
                            v-for="(pp, pk) in algo.params"
                            :key="pk"
                            class="algo-detail-list"
                          >
                            <div
                              v-if="pp.type === 'select'"
                              class="algo-detail-item"
                            >
                              <div class="algo-detail-title">
                                <texts
                                  :text="
                                    $t('recipe.param.' + algo.name + '.' + pk)
                                  "
                                  size="small"
                                  color="gray"
                                />
                              </div>
                              <div class="algo-detail-value">
                                <!-- SAMME.Rというvalueがi18nで変換できないため、このSAMME.RをSAMME_Rなどにもとのデータ自体を変換して、i18nの方もSAMME_Rにする必要がある -->
                                <template v-for="(option, index) in pp.options">
                                  <texts
                                    :key="index"
                                    class="algo-detail-value-text"
                                    :text="
                                      $t(
                                        'recipe.param.' +
                                          algo.name +
                                          '.' +
                                          checkValueEscape(option.text)
                                      )
                                    "
                                  />
                                </template>
                              </div>
                            </div>
                            <div v-else class="algo-detail-item">
                              <div class="algo-detail-title">
                                <texts
                                  :text="
                                    $t('recipe.param.' + algo.name + '.' + pk)
                                  "
                                  size="small"
                                  color="gray"
                                />
                              </div>
                              <div class="algo-detail-value">
                                <texts :text="$t('recipe.autoflow.range')" />
                              </div>
                              <div class="algo-detail-range">
                                <div class="algo-detail-range-item">
                                  <texts
                                    :text="$t('recipe.autoflow.low')"
                                    size="small"
                                    color="gray"
                                  />
                                  <texts :text="pp.low" />
                                </div>
                                <div class="algo-detail-range-item">
                                  <texts
                                    :text="$t('recipe.autoflow.high')"
                                    size="small"
                                    color="gray"
                                  />
                                  <texts :text="pp.high" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                </transition>
              </div>
            </template>
          </div>
          <!-- パラメーター一覧 -->
          <div
            v-else-if="Object.keys(parameters.node.params).length > 0"
            key="list"
            class="list"
          >
            <transition-group name="toggle-parameter" mode="out-in">
              <template v-for="(param, name, index) in parameters.node.params">
                <div
                  v-if="
                    showParams(param, name, parameters.node.params) &&
                      displayParams(param)
                  "
                  :key="param + index + name + parameters.node.id"
                  class="item"
                >
                  <!-- 活性化関数 -->
                  <div v-if="name === 'act'" class="param">
                    <div class="param-name">
                      <texts
                        class="param-name-text"
                        :text="$t('recipe.param.activation')"
                        size="small"
                        color="gray"
                        isShowAll
                      />
                    </div>
                    <div class="param-body">
                      <div v-if="edit" class="param-selectbox">
                        <select-box
                          :firstSelectItem="{
                            name: activationText(param.value),
                            value: param.value
                          }"
                          :items="activationOptions"
                          isGray
                          min
                          @input="
                            $emit('input', {
                              param: $event,
                              paramName: name,
                              blockId: parameters.node.id
                            })
                          "
                        />
                      </div>
                      <div v-else>
                        <texts :text="activationText(param.value)" />
                      </div>
                    </div>
                  </div>
                  <!-- TODO: パラメーターの変更を受け取ってparam,paramName,blockIdにわけてemitしていますほかの方法がよければほかの方法にします -->
                  <parameter
                    v-else
                    :param="param"
                    :name="name"
                    :translatedName="getParamName(parameters.node, name)"
                    :type="param.type"
                    :value="param.value"
                    :layerName="parameters.node.name"
                    :edit="edit"
                    :extra_selections="param.extra_selections"
                    :options="setOptions(parameters.node.name, param.options)"
                    :step="param.step"
                    :force-step="param.forceStep"
                    :min="paramMin(param)"
                    :max="paramMax(param)"
                    :list_body="param.list_body"
                    :defaultValue="param.defaultValue"
                    :defaultNumValue="param.defaultNumValue"
                    :disabled="checkDisabled(param, name)"
                    @input="
                      $emit('input', {
                        param: $event,
                        paramName: name,
                        blockId: parameters.node.id
                      })
                    "
                  />
                </div>
              </template>
            </transition-group>
          </div>
        </transition>
        <transition name="toggle-parameter" mode="out-in">
          <div
            v-if="parameters.incomeEdges.length || parameters.outgoEdges.length"
            class="edge-wrap"
          >
            <texts
              class="edge-title"
              :text="$t('recipe.sidebar.ConnectedLayer')"
              color="gray"
              size="small"
            />
            <div class="edge-list">
              <transition-group
                class="edge-list-inner"
                name="toggle-parameter"
                mode="out-in"
              >
                <div
                  v-for="(edge, index) in connectionEdges"
                  :key="index + '_edge'"
                  class="edge-item"
                >
                  <text-with-icon
                    class="edge-item-inner"
                    :text="
                      convertCustomblockName(edge.customblockId, edge.name)
                    "
                    :iconName="edge.iconName"
                  />
                  <icons
                    v-if="edit"
                    class="edge-item-button"
                    iconName="close"
                    isButton
                    size="small"
                    @icon-click="$emit('remove-edge', edge.id)"
                  />
                </div>
              </transition-group>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
import loadingIcon from '@/components/atoms/loading-icon'
import textWithTitle from '@/components/molecules/text-with-title'
import textWithIcon from '@/components/molecules/text-with-icon'
import selectBox from '@/components/molecules/select-box'
import parameter from './parameter'
import checkboxBase from '@/components/atoms/checkbox-base'

export default {
  components: {
    texts,
    icons,
    loadingIcon,
    textWithTitle,
    textWithIcon,
    selectBox,
    parameter,
    checkboxBase
  },
  data() {
    return {
      /** オートフローのアルゴリズムを表示中かどうか */
      showAutoFlowAlgorithm: true,
      /** オートフローのアルゴリズムの中で表示しているもの */
      showParamFlagList: [],

      customblockVersionsLoadedId: null
    }
  },
  watch: {
    async parameters() {
      if (this.parameters?.node?.name === 'customblock') {
        const customBlockId = this.parameters.node.customblockId
        const version = this.parameters.node.customblockVersion
        this.$emit('load-detail', { customBlockId, version })
      }
    }
  },
  async mounted() {
    if (this.parameters?.node?.name === 'customblock') {
      const customBlockId = this.parameters.node.customblockId
      const version = this.parameters.node.customblockVersion
      this.$emit('load-detail', { customBlockId, version })
    }
  },
  props: {
    /** パラメーター一覧 */
    parameters: Object,
    /** 編集中かどうか */
    edit: Boolean,
    /** これ何かわかっていません、、、clientから持ってきましたがこれがないと崩れる、、、 */
    changeInputDataType: { type: Function, default: function () {} },

    customblockDetail: Object,
    loadingCustomblockDetail: Boolean,
    customblockVersions: Array,
    customblockList: Array
  },
  computed: {
    /** 回帰と分類で異なるアルゴリズムの表示をだしわけ */
    autoFlowAlgorithmList() {
      /* if (!this.selectedSingle) return []
         今は一個しか選択できないので外しています
       */
      let isCurrentVersion = false
      const algoType = this.parameters.node.params.type.value
      const initAlgorithm =
        this.parameters.node.params.algorithm[algoType].value
      const algoParam = this.parameters.node.params.algorithm
      let algorithmList = []
      if (!algoParam) {
        algorithmList = initAlgorithm
      } else if (!algoParam[algoType]) {
        const oldVersionAlgorithm = this.parameters.node.params.algorithm.value // classification only
        algorithmList =
          algoType === 'classification' && oldVersionAlgorithm
            ? oldVersionAlgorithm
            : initAlgorithm
      } else if (Array.isArray(algoParam[algoType])) {
        // the recipe is inconsistency because it has too old params. initiaize the algorithms.
        algorithmList = initAlgorithm
      } else {
        isCurrentVersion = true
      }
      if (isCurrentVersion === false) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.parameters.node.params.algorithm = {
          [algoType]: {
            value: algorithmList
          }
        }
      }
      return this.parameters.node.params.algorithm[algoType].value
    },
    /** 活性化関数の選択項目一覧 */
    activationOptions() {
      return [
        { value: 'relu', name: 'ReLU' },
        { value: 'ident', name: this.$t('recipe.activation.ident') },
        { value: 'sigmoid', name: 'Sigmoid' },
        { value: 'leaky_relu', name: 'Reaky ReLU' },
        { value: 'tanh', name: 'tanh' },
        { value: 'softplus', name: 'Softplus' },
        { value: 'softmax', name: 'Softmax' },
        { value: 'elu', name: 'ELU' },
        { value: 'selu', name: 'SELU' },
        { value: 'relu6', name: 'ReLU6' },
        { value: 'crelu', name: 'CReLU' },
        { value: 'swish', name: 'swish' }
      ]
    },
    connectionEdges() {
      const income = this.parameters.incomeEdges.map((e) => {
        return {
          name: e.from.name,
          id: e.id,
          iconName: 'arrow',
          customblockId: e.from?.customblockId ?? null
        }
      })
      const outcome = this.parameters.outgoEdges.map((e) => {
        return {
          name: e.to.name,
          id: e.id,
          iconName: 'arrowBack',
          customblockId: e.to?.customblockId ?? null
        }
      })
      return income.concat(outcome)
    }
  },
  methods: {
    getLayerName(node) {
      if (node.name === 'customblock') {
        if (this.loadingCustomblockDetail) {
          return 'loading'
        }
        return (
          this.customblockDetail?.name ??
          this.$t('customblock.error.unknownBlock')
        )
      }
      return this.$t('recipe.layerNames.' + node.name)
    },
    getParamName(node, param) {
      if (node.name === 'customblock') {
        if (this.loadingCustomblockDetail) {
          return 'loading'
        }
        const customblock = this.customblockDetail
        return customblock?.params?.[param]?.name ?? param
      }
      return this.$t('recipe.param.' + node.name + '.' + param)
    },
    updateValues(e) {
      this.$emit('input', e)
    },
    /** 活性化関数の初期選択項目 */
    activationText(value) {
      const text = this.activationOptions.filter((e) => e.value === value)
      return text[0].name
    },
    /** パラメーターのオプションをi18nに変換 */
    setOptions(layerName, options) {
      const newOptions = []
      if (options) {
        options.forEach((v) => {
          const name =
            layerName === 'customblock'
              ? v.text
              : this.$t(
                  'recipe.param.' +
                    layerName +
                    '.' +
                    this.checkValueEscape(v.text)
                )
          newOptions.push({
            value: v.value,
            name: name
          })
        })
      }
      return newOptions
    },
    /** オートフローのアルゴリズムの開閉 */
    showArgoDetail(index) {
      if (this.showParamFlagList.indexOf(index) > -1) {
        const target = this.showParamFlagList.indexOf(index)
        this.showParamFlagList.splice(target, 1)
      } else {
        this.showParamFlagList.push(index)
      }
    },
    /** オートフローのアルゴリズムの開閉時のアコーディオン */
    enter(target) {
      target.style.height = target.scrollHeight + 'px'
    },
    leave(target) {
      target.style.height = '0'
    },
    checkValueEscape(value) {
      let target = value
      if (target.includes('.')) {
        target = target.replace(/\./, '_')
      }
      return target
    },
    async versionSelectOpen() {
      const customBlockId = this.parameters.node.customblockId
      if (this.customblockVersionsLoadedId === customBlockId) return

      this.$emit('fetch-version-list', { customBlockId })

      this.customblockVersionsLoadedId = customBlockId
    },
    versionSelectInput(e) {
      this.$emit('version-change', {
        blockId: this.parameters.node.id,
        newVersion: e
      })
    },
    convertCustomblockName(id, name) {
      if (this.customblockList == null || this.customblockList.lengt === 0)
        return
      if (id == null) {
        return this.$t('recipe.layerNames.' + name)
      } else {
        const target = this.customblockList.find((customblock) => {
          return customblock.customblock_id === id
        })
        return target.name
      }
    },
    // TODO: `displayParams` と共通化; https://trello.com/c/I4l1R6jL
    showParams(params, name, paramsParent) {
      if (params.algorithm_if == null) return true
      const algorithm = this.parameters.node.params.algorithm.value
      if (params.algorithm_if.indexOf(algorithm) === -1) {
        return false
      }
      // 非編集中状態で、dbscan_min_samplesはdbscan_epsがautoの場合は非表示にする特別対応
      if (name === 'dbscan_min_samples' && !this.edit) {
        return paramsParent.dbscan_eps.value !== 'auto'
      }
      return true
    },
    // `disableShow` の設定に応じて、パラメータの表示有無を調整する（1つでも表示しない設定があれば表示させない仕様）
    // ※ `showParams` と異なり、汎用的に利用できるような処理としている
    displayParams(params) {
      if (!Object.prototype.hasOwnProperty.call(params, 'disableShow'))
        return true
      const disableShow = Object.entries(params.disableShow).some(
        ([key, value]) => {
          const checkTarget = this.parameters.node.params[key].value
          return value.indexOf(checkTarget) >= 0
        }
      )
      return !disableShow
    },
    // minTargetで指定したパラメータがminよりも大きい場合、minTargetで指定されている値をminにする
    paramMin(param) {
      if (param?.min == null && param?.minTarget == null)
        return param?.min ?? null
      const checkTargetValue =
        this.parameters.node.params[param.minTarget] ?? null
      if (checkTargetValue == null) {
        return param?.min ?? null
      } else if (param?.min == null) {
        return checkTargetValue.value
      } else {
        return checkTargetValue.value > param.min
          ? checkTargetValue.value
          : param.min
      }
    },
    // maxTargetで指定したパラメータがmaxよりも小さい場合、maxTargetで指定されている値をmaxにする
    paramMax(param) {
      if (param?.max == null && param?.maxTarget == null)
        return param?.max ?? null
      const checkTargetValue =
        this.parameters.node.params[param.maxTarget] ?? null
      if (checkTargetValue == null) {
        return param?.max ?? null
      } else if (param?.max == null) {
        return checkTargetValue.value
      } else {
        return checkTargetValue.value < param.max
          ? checkTargetValue.value
          : param.max
      }
    },
    checkDisabled(param, name) {
      if (name === 'dbscan_min_samples') {
        // dbscan_min_samplesは、dbscan_epsと連動する特殊な表示設定
        const dbscanEps = this.parameters.node.params.dbscan_eps.value
        if (dbscanEps === 'auto') {
          return true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  overflow: hidden;
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  padding: $space-small 0;
}
.body {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.title {
  margin: 0 0 $space-small;
}
.main {
  flex-shrink: 0;
  padding: 0 $space-small;
  margin: 0 0 $space-small;
  + .edge-wrap {
    margin: 0;
  }
  &:last-of-type {
    margin: 0;
  }
}
.list {
  overflow-x: hidden;
  overflow-y: scroll;
  flex-shrink: 1;
  height: 100%;
  padding: 0 $space-base 0 $space-small;
  margin: 0 $space-base 0 0;
  @include scrollbar;
}
.item {
  margin: 0 0 $space-small;
  &:last-of-type {
    margin: 0;
  }
}
.param {
  &-name {
    margin: 0 0 $space-text;
    &-text {
      white-space: pre-line;
    }
  }
  &-selectbox {
    width: calc(100% - adjustVW(2));
    height: adjustVW(40);
  }
}
.input {
  padding: 0 $space-small;
}

.algo {
  &-wrap {
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 $space-small;
    margin-right: $space-base;
    @include scrollbar;
  }
  &-button {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 $space-sub;
    &:hover {
      opacity: 1;
    }
    &-icon {
      margin: 0 $space-text 0 0;
      transition: transform $transition-base;
    }
    &-toggle {
      .algo-button-icon {
        transform: rotate(180deg);
      }
    }
  }
  &-item {
    padding: 0 $space-text;
    margin: 0 0 $space-small;
    border-bottom: $border-main;
    &:last-of-type {
      margin: 0;
      border-bottom: none;
    }
    &-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0 0 $space-small;
      &:hover {
        opacity: 1;
      }
    }
    &-checkbox {
      display: flex;
      flex-shrink: 1;
      align-items: center;
      width: 100%;
      padding: 0 $space-small 0 0;
      &-text {
        margin: 0 0 0 #{adjustVW(-20)};
        text-align: left;
        white-space: pre-line;
      }
    }
    &-icon {
      transition: transform $transition-base;
      &-toggle {
        transform: rotate(180deg);
      }
    }
  }
  &-inner {
    overflow: hidden;
    transition: opacity $transition-base ease-in-out,
      transform $transition-base ease-in-out;
    will-change: opacity, transform;
  }
  &-detail {
    overflow: hidden;
    height: 0;
    transition: opacity $transition-base ease-in-out,
      height $transition-base ease-in-out,
      transform $transition-base ease-in-out;
    will-change: opacity, height, transform;
    &-title {
      margin: 0 0 $space-text;
    }
    &-item {
      margin: 0 0 $space-small;
    }
    &-range {
      display: flex;
      &-item {
        width: 50%;
      }
    }
    &-value {
      display: flex;
      flex-wrap: wrap;
      &-text {
        margin: 0 $space-sub $space-text 0;
        white-space: pre-line;
        &:last-of-type {
          margin: 0;
        }
      }
      + .algo-detail-range {
        margin: $space-base 0 0;
      }
    }
  }
}

.edge {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    max-height: 50%;
    padding: $space-small $space-base 0 $space-small;
    margin: $space-small 0 0;
    border-top: $border-sub;
  }
  &-title {
    flex-shrink: 0;
    margin: 0 0 $space-base;
  }
  &-list {
    overflow: hidden;
    flex-shrink: 1;
    height: 100%;
    margin: 0 0 $space-base;
    &:last-of-type {
      margin: 0;
    }
    &-inner {
      overflow-x: hidden;
      overflow-y: scroll;
      display: block;
      height: 100%;
      padding: 0 $space-base 0 0;
      @include scrollbar;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    margin: 0 0 $space-base;
    &-inner {
      overflow: hidden;
      flex-shrink: 1;
      margin: 0 $space-base 0 0;
    }
    &-button {
      flex-shrink: 0;
    }
    &:last-of-type {
      margin: 0;
    }
  }
}

.toggle-parameter-enter-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-parameter-leave-active {
  transition: transform $transition-base;
}
.toggle-parameter-enter,
.toggle-parameter-leave-to {
  position: absolute;
  opacity: 0;
  transform: translateX($space-medium);
  will-change: opacity, transform;
}

.toggle-algo-enter-active,
.toggle-algo-leave-active {
  opacity: 1;
  transform: translateY(0);
}
.toggle-algo-enter,
.toggle-algo-leave-to {
  opacity: 0;
  transform: translateY(-$space-small);
}

.c-textblock {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &-left {
      justify-content: flex-start;
    }
  }
  &-title {
    margin-bottom: $space-text;
  }
}
.loading {
  padding-left: $space-small;
}
</style>
