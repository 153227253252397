<!-- 検索したブロック一覧 -->
<template>
  <div class="body">
    <button class="title" @click="$emit('reset-select')">
      <icons class="icon" iconName="backward" size="large" />
      <texts
        :text="'[' + cropText + ']' + $t('recipe.searchBlock.result')"
        size="large"
      />
    </button>
    <div class="block-list">
      <block-list
        v-if="searchedBlocks.length > 0"
        :filteredBlocks="searchedBlocks"
        @block-click="$emit('block-click', $event)"
      />
      <div class="block-list-not-found">
        <images imageName="notFound" class="block-list-not-found-image" />
        <texts
          :text="'[ ' + searchText + ' ] ' + $t('recipeEdit.noSearchResults')"
          class="block-list-not-found-text"
          size="small"
        />
      </div>
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import texts from '@/components/atoms/text'
import Images from '@/components/atoms/images.vue'
import blockList from '@/components/molecules/recipe/block-list.vue'
export default {
  components: {
    icons,
    texts,
    Images,
    blockList
  },
  props: {
    /** 検索したブロックタイプ */
    searchedBlocks: Array,
    /** 検索した文字列 */
    searchText: String
  },
  computed: {
    cropText() {
      if (this.searchText.length < 8) {
        return this.searchText
      }
      return this.searchText.substr(0, 7) + '...'
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: $space-small $space-base $space-small $space-small;
}
.title {
  display: flex;
  align-items: center;
  margin: 0 0 $space-small;
}
.icon {
  margin: 0 $space-base 0 0;
}
.block-list {
  overflow: hidden;
  height: 100%;
  &-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    &-image {
      width: adjustVW(240);
      margin-bottom: $space-medium;
    }
    &-text {
      white-space: pre-line;
    }
  }
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $space-text $space-small;
  margin: 0 0 $space-small;
  border-bottom: $border-main;
  &:last-of-type {
    margin: 0;
  }
}
</style>
