<template>
  <div v-if="!hiddenParams" class="param">
    <div class="param-name">
      <template v-if="!notEdditingHiddenItem(name)">
        <div class="param-name-text-with-icon">
          <texts
            class="param-name-text"
            size="small"
            color="gray"
            :text="translatedParamName"
          />
          <!-- 設定値の末尾に"Help"のついたkeyがあればその内容をtooltipとして表示 -->
          <button
            v-if="$te('recipe.param.' + layerName + '.' + name + 'Help')"
            v-tooltip.right="
              $t('recipe.param.' + layerName + '.' + name + 'Help')
            "
            class="param-name-text-with-icon-button"
          >
            <icons iconName="info" size="16" />
          </button>
        </div>
      </template>
    </div>
    <div class="param-body">
      <transition name="fade" mode="out-in">
        <!-- 非編集中状態 -->
        <div v-if="!edit" key="notEdit">
          <!-- 選択ボックス -->
          <div v-if="type === 'select' && layerName">
            <texts
              v-if="layerName === 'customblock'"
              :text="convertCustomblock(value)"
            />
            <texts
              v-else
              :text="
                $t('recipe.param.' + layerName + '.' + checkValueEscape(value))
              "
            />
          </div>
          <!-- チェックボックス -->
          <div v-else-if="type === 'bool'">
            <div class="param-checkbox">
              <checkbox-base :checked="value" isDisabled />
            </div>
          </div>
          <!-- リスト形式（時系列） -->
          <div v-else-if="type === 'list'">
            <param-period
              v-model="internalValue"
              :layerName="layerName"
              :name="name"
              :edit="edit"
              :list_body="list_body"
            />
          </div>
          <!-- チェックボックス -->
          <div v-else-if="type === 'checkbox'">
            <div class="param-checkbox-not-edditing">
              <template v-for="(item, index) in value">
                <texts
                  v-if="layerName === 'customblock'"
                  :key="index"
                  :text="convertCustomblock(item)"
                />
                <texts v-else :key="index" :text="item" />
              </template>
            </div>
          </div>
          <div
            v-else-if="
              type === 'listItem' &&
                $te('recipe.param.' + layerName + '.' + value)
            "
          >
            <texts :text="$t('recipe.param.' + layerName + '.' + value)" />
          </div>
          <!-- ラジオボタンの表示 -->
          <div v-else-if="type === 'number_select'">
            <texts :text="radioButtonTranslation(value)" :error="error" />
          </div>
          <!-- それ以外 -->
          <div v-else>
            <texts :text="value" :error="error" />
          </div>
        </div>
        <!-- 編集中状態 -->
        <div v-else key="edit" v-tooltip="numValueTips">
          <!-- ラジオボタンとインプットBoxの組み合わせ -->
          <div v-if="type === 'number_select'">
            <radio-input-list
              v-model="internalValue"
              class="param-radio-input"
              :items="extra_selections"
              :translatedNames="translatedExtraSelectionsName"
              :tooltipList="tooltipExtraSelectionsList"
              :name="name"
              :min="min"
              :max="max"
              :step="setStep"
              :defaultNumValue="radioInputNumValue ? radioInputNumValue : min"
            />
          </div>
          <!-- チェックボックス -->
          <div v-else-if="type === 'bool'" class="param-checkbox">
            <checkbox-base v-model="internalValue" :checked="value" />
          </div>
          <!-- チェックボックスリスト -->
          <div v-else-if="type === 'checkbox'">
            <checkbox-list-recipe
              v-model="internalValue"
              :checked="value"
              :values="options"
            />
          </div>
          <!-- 選択ボックス -->
          <div v-else-if="type === 'select'" class="param-selectbox">
            <select-box
              v-model="internalValue"
              :firstSelectItem="{
                name: options.filter((e) => e.value === value)[0].name,
                value: value
              }"
              :items="options"
              isGray
              min
              :scrollBar="scrollBar"
              :scrollSize="scrollSize"
            />
          </div>
          <!-- 数値入力 -->
          <div v-else-if="type === 'number' || type === 'float'">
            <input-box
              ref="inputBox"
              type="number"
              :value="value"
              :min="min"
              :max="max"
              :step="setStep"
              :required="!(defaultValue == null || defaultValue === '')"
              isGray
              forceStep
              :error="error"
              @blur="resetTips"
              @input="updateNumInputValue($event)"
              @change="updateNumValue($event)"
              @focus="$emit('focus')"
            />
          </div>
          <!-- Stepが１の場合の数値入力 -->
          <div v-else-if="type === 'int'">
            <input-box
              ref="inputBox"
              type="number"
              :value="value"
              :min="min"
              :max="max"
              :step="1"
              :required="!(defaultValue == null || defaultValue === '')"
              isGray
              :error="error"
              @blur="resetTips"
              @input="updateNumInputValue($event, true)"
              @change="updateNumValue($event, true)"
              @focus="$emit('focus')"
            />
          </div>
          <!-- リスト追加可能な形式（時系列） -->
          <div v-else-if="type === 'list'">
            <param-period
              v-model="internalValue"
              :layerName="layerName"
              :name="name"
              :edit="edit"
              :list_body="list_body"
            />
          </div>
          <!-- それ以外 -->
          <div v-else>
            <input-box
              v-model="internalValue"
              :type="type"
              :value="value"
              :error="error"
              isGray
              @blur="onBlur"
              @focus="$emit('focus')"
            />
          </div>
          <transition-toggle-contents>
            <div
              v-if="disabledTips"
              v-tooltip="disabledTips"
              class="disabled-wrap"
            />
          </transition-toggle-contents>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon.vue'
import checkboxBase from '@/components/atoms/checkbox-base.vue'
import inputBox from '@/components/molecules/input-box'
import selectBox from '@/components/molecules/select-box'
import radioInputList from '@/components/molecules/radio-input-list'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import checkboxListRecipe from './checkbox-list-recipe.vue'
import paramPeriod from './inputs/period.vue'

export default {
  name: 'parameter',
  components: {
    texts,
    icons,
    checkboxBase,
    inputBox,
    selectBox,
    radioInputList,
    transitionToggleContents,
    checkboxListRecipe,
    paramPeriod
  },
  data() {
    return {
      checked: this.value,
      hiddenItem: ['seasonality'],
      // デフォルトの値ではなく、ブロックを選択したときに現在入力されている値を、inputを空にしたときに代入した値とする
      selectStartValue: null,
      isMax: false,
      isMin: false,
      isRequired: false,
      isNotInt: false
    }
  },
  props: {
    /** ブロックの名前 */
    layerName: String,
    /** パラメーターの名前 */
    name: String,
    /** 翻訳名 */
    translatedName: String,
    /** パラメーターの形式 */
    type: String,
    /** 編集中かどうか */
    edit: { type: Boolean, default: true },
    /** 数値入力のステップ */
    forceStep: { type: Boolean, default: false },
    /** パラメーターの初期値 */
    value: {},
    /** 数値とラジオボタンの入力で、数値入力項目以外の選択項目 */
    extra_selections: { type: Array, default: () => [] },
    /** 選択ボックスで選択できる項目 */
    options: { type: Array, default: () => [] },
    /** 数値入力のステップ */
    step: {
      type: Number,
      default: null
    },
    /** disabled */
    disabled: { type: Boolean, default: false },
    /** 数値入力の最小値 */
    min: { type: Number, default: Number.NEGATIVE_INFINITY },
    /** 数値入力の最大値 */
    max: { type: Number, default: Number.POSITIVE_INFINITY },
    /** エラーメッセージなど */
    infoMessage: { type: String, default: '' },
    /** リスト追加可能なパラメーター（時系列など）で使用するリストのオブジェクト */
    list_body: { type: Object, default: () => {} },
    error: { type: Boolean, default: false },
    /** デフォルト値があるならセット */
    defaultValue: { type: [Number, String], default: null },
    /** 数値入力とラジオボタンの場合に、デフォルト値があるならセットなければ最小値が入る */
    defaultNumValue: { type: Number, default: null },
    /** セレクトボックスのスクロールバー有無 */
    scrollBar: { type: Boolean, default: false },
    /** セレクトボックスにスクロール領域をつける場合に、セレクトボックスの大きさを指定 */
    scrollSize: { type: Number }
  },
  methods: {
    addListBody() {
      const body = JSON.parse(JSON.stringify(this.list_body))
      this.$emit('input', [...this.value].unshift(body))
    },
    deleteListBody(index) {
      this.$emit('input', [...this.value].splice(index, 1))
    },
    setOptions(layerName, options) {
      const newOptions = []
      if (options) {
        options.forEach((v) => {
          newOptions.push({
            value: v.value,
            text: this.$t(
              'recipe.param.' + layerName + '.' + this.checkValueEscape(v.text)
            )
          })
        })
      }
      return newOptions
    },
    notEdditingHiddenItem(name) {
      if (this.edit) return false
      return this.hiddenItem.includes(name)
    },
    checkValueEscape(value) {
      let target = value
      if (target.includes('.')) {
        target = target.replace(/\./, '_')
      }
      return target
    },
    resetTips() {
      this.isRequired = false
      this.isMax = false
      this.isMin = false
      this.isNotInt = false
      this.onBlur()
    },
    updateNumInputValue(val, int) {
      this.isMax = false
      this.isMin = false
      this.isRequired = false
      this.isNotInt = false
      if (this.max != null && val >= this.max) {
        this.isMax = true
      } else if (this.min != null && this.min >= val) {
        this.isMin = true
      } else if (isNaN(val)) {
        this.isRequired = true
      } else if (int && !Number.isInteger(val)) {
        this.isNotInt = true
      }
    },
    updateNumValue(val, int) {
      this.isMax = false
      this.isMin = false
      this.isRequired = false
      this.isNotInt = false
      let value = val
      if (this.max != null && value >= this.max) {
        value = this.max
        this.isMax = true
      } else if (this.min != null && this.min >= value) {
        value = this.min
        this.isMin = true
      } else if (isNaN(value)) {
        value = this.selectStartValue
        this.isRequired = true
      } else if (int && !Number.isInteger(val)) {
        value = Math.ceil(val)
        this.isNotInt = true
      }
      if (this.value === value) {
        this.$refs.inputBox.updateValueByParent(value)
      }
      this.$emit('input', value)
    },
    onBlur() {
      this.$emit('blur')
    },
    convertCustomblock(value) {
      if (this.layerName !== 'customblock') return null
      const target = this.options.find((option) => {
        return option.value === value
      })
      return target.name
    },
    radioButtonTranslation(value) {
      const target = `recipe.param.${this.layerName}.${this.name}Extra.${value}`
      return this.$te(target) ? this.$t(target) : value
    }
  },
  computed: {
    translatedParamName() {
      return (
        this.translatedName ??
        this.$t('recipe.param.' + this.layerName + '.' + this.name)
      )
    },
    translatedExtraSelectionsName() {
      if (this.extra_selections.length === 0) return []
      return this.extra_selections.map((selection) => {
        const target =
          'recipe.param.' +
          this.layerName +
          '.' +
          this.name +
          'Extra.' +
          selection
        return this.$te(target) ? this.$t(target) : selection
      })
    },
    tooltipExtraSelectionsList() {
      if (this.extra_selections.length === 0) return []
      return this.extra_selections.map((selection) => {
        const target =
          'recipe.param.' +
          this.layerName +
          '.' +
          this.name +
          'Tips.' +
          selection
        return {
          content: this.$te(target) ? this.$t(target) : ''
        }
      })
    },
    internalValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    hiddenParams() {
      // 非表示にしたいパラメータ
      if (
        this.layerName === 'HRFlowAttritionPrediction' &&
        this.name === 'algorithm'
      )
        return true
      return false
    },
    numValueTips() {
      if (this.isMax) {
        return {
          content: this.$t('recipe.editTips.max', { max: this.max }),
          show: this.isMax,
          trigger: 'manual'
        }
      } else if (this.isMin) {
        return {
          content: this.$t('recipe.editTips.min', { min: this.min }),
          show: this.isMin,
          trigger: 'manual'
        }
      } else if (this.isRequired) {
        return {
          content: this.$t('recipe.editTips.required', {
            defaultValue: this.selectStartValue
          }),
          show: this.isRequired,
          trigger: 'manual'
        }
      } else if (this.isNotInt) {
        return {
          content: this.$t('recipe.editTips.isNotInt'),
          show: this.isNotInt,
          trigger: 'manual'
        }
      } else {
        return {
          content: '',
          trigger: 'manual'
        }
      }
    },
    setStep() {
      // Stepがある場合はStep
      if (this.step) return this.step
      const baseCount = 0.01
      // 整数や数値以外の値の場合は0.01
      if (
        !this.defaultValue ||
        isNaN(this.defaultValue) ||
        Number.isInteger(this.defaultValue)
      )
        return baseCount
      const countDeclimal = this.defaultValue
        ? this.defaultValue.toString().split('.')[1]
        : 0
      // 0.1以下の場合だけその桁数に合わせてstepを変更
      if (countDeclimal.length < 2) return baseCount
      return 1 / 10 ** (countDeclimal.length + 1)
    },
    radioInputNumValue() {
      if (this.type !== 'number_select') return null
      if (this.defaultNumValue) return this.defaultNumValue
      if (isNaN(this.selectStartValue)) return this.min
      return this.selectStartValue
    },
    // 特殊な表示設定などでdisabledにする処理 disabledのときに、i18nにparamDisabledがあれば、表示される
    disabledTips() {
      if (!this.disabled) return false
      const tipsTarget = `recipe.param.${this.layerName}.${this.name}Disabled`
      if (this.$te(tipsTarget)) {
        return this.$t(tipsTarget)
      }
      return false
    }
  },
  mounted() {
    this.selectStartValue = this.value
  },
  destroyed() {
    this.selectStartValue = null
  }
}
</script>

<style lang="scss" scoped>
.param {
  &-name {
    margin: 0 0 $space-text;
    &-text {
      white-space: pre-line;
      &-with-icon {
        display: flex;
        align-items: center;
        grid-column-gap: $space-base;
        &-button {
          margin-top: adjustVW(1);
          cursor: help;
        }
      }
    }
  }
  &-checkbox {
    height: adjustVW(16);
    margin: adjustVW(20) 0 0;
    &-not-edditing {
      display: flex;
      > div {
        margin-right: $space-base;
        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
  &-radio-input {
    margin: $space-sub 0 0;
  }
  &-selectbox {
    width: calc(100% - adjustVW(2));
    height: adjustVW(40);
  }
  &-body {
    position: relative;
  }
}
.list {
  &-button {
    display: block;
    width: 100%;
    padding: $space-base $space-sub;
    margin: 0 0 $space-small;
    border-radius: 9in;
    transition: background-color $transition-base, opacity $transition-base;
    &:hover {
      background: $background-sub;
      opacity: 0.5;
    }
    &:active {
      background: $background-sub;
      opacity: 1;
    }
    &-delete {
      @extend .list-button;
      width: fit-content;
      margin: 0 0 0 auto;
    }
  }
  &-inner {
    padding: 0 0 $space-small;
    margin: 0 0 $space-small;
    border-bottom: $border-main;
    &:last-of-type {
      border: none;
    }
  }
}

.item {
  margin: 0 0 $space-small;
}

.add-list-enter-active {
  transition: opacity $transition-base;
}
.add-list-enter {
  opacity: 0;
  will-change: opacity;
}

.fade-enter-active,
.fade-leave-active {
  transition: transform $transition-base, opacity $transition-base;
  will-change: opacity;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX($space-medium);
  will-change: opacity, transform;
}

.disabled-wrap {
  position: absolute;
  top: -$space-text;
  left: adjustVW(-6);
  width: calc(100% + $space-sub);
  height: calc(100% + $space-base);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: adjustVW(6);
  z-index: 1;
}
</style>
