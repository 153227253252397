<template>
  <div class="body-wrap" :class="{ 'body-wrap-active': active }">
    <Graph
      ref="graph"
      class="graph"
      :graph.sync="edit"
      :recipeLayers="recipeLayers"
      :customblockList="customblockList"
      edittable
      @select="selectNode"
      @update:warnings="$emit('update:warnings', $event)"
      @drop-customblock="$emit('drop-customblock', $event)"
    />
    <div v-if="recipe" class="l-side">
      <recipe-edit-side-palette
        :recipeLayers="recipeLayers"
        :customblockList="customblockList"
      />
    </div>
    <div v-if="recipe" class="r-side">
      <recipe-edit-side-params
        :selectedNodes="selectedNodes"
        :customblockDetail="customblockDetail"
        :loadingCustomblockDetail="loadingCustomblockDetail"
        :customblockVersions="customblockVersions"
        :customblockList="customblockList"
        editting
        @input="paramChanged"
        @remove-edge="removeEdge($event)"
        @remove-node="removeNode($event)"
        @load-detail="$emit('load-detail', $event)"
        @version-change="versionChange($event)"
        @fetch-version-list="$emit('fetch-version-list', $event)"
      />
    </div>
  </div>
</template>
<script>
import Graph from '@/components/organisms/recipe-detail/editor/graph.vue'
import recipeEditSidePalette from './recipe-edit-side-palette.vue'
import recipeEditSideParams from './recipe-edit-side-params.vue'
import { convertParams } from '@/lib/customblock/convert.js'

export default {
  components: {
    Graph,
    recipeEditSidePalette,
    recipeEditSideParams
  },
  data() {
    return {
      edit: JSON.parse(JSON.stringify(this.recipe.body)),
      selectedNodes: []
    }
  },
  props: {
    recipe: Object,
    recipeLayers: Object,
    search: String,
    active: Boolean,
    warnings: Array,
    customblockList: Array,
    customblockDetail: Object,
    loadingCustomblockDetail: Boolean,
    customblockVersions: Array,
    customblockVersionParams: Object
  },
  watch: {
    customblockVersionParams(args) {
      this.versionApply(args)
    }
  },
  computed: {},
  async mounted() {
    this.$nextTick(() => this.$refs.graph.buildGraph())
  },
  methods: {
    selectNode(e) {
      this.$refs.graph.updateData()
      this.selectedNodes = e.nodes
    },
    paramChanged({ blockId, paramName, param }) {
      this.edit.layers.find((x) => x.id === blockId).params[paramName].value =
        param
      // eslint-disable-next-line no-self-assign
      this.edit = this.edit

      this.$refs.graph.checkNodeWarning(blockId)
      if (this.selectedNodes[0].outgoEdges.length) {
        const sourceNodeOutGo = this.selectedNodes[0].node
        const targetNodeOutGo = this.selectedNodes[0].outgoEdges[0].to
        this.$refs.graph.checkConnectionWarning(
          sourceNodeOutGo,
          targetNodeOutGo,
          true
        )
      }
      if (this.selectedNodes[0].incomeEdges.length > 0) {
        const sourceNodeIncome = this.selectedNodes[0].incomeEdges[0].from
        const targetNodeIncome = this.selectedNodes[0].node
        this.$refs.graph.checkConnectionWarning(
          sourceNodeIncome,
          targetNodeIncome,
          true
        )
      }
      this.$refs.graph.updateData()
    },
    async versionApply({ blockId, newVersion, newTemplateParams }) {
      const targetBlock = this.edit.layers.find((x) => x.id === blockId)
      const newParams = convertParams(targetBlock.params, newTemplateParams)

      this.$refs.graph.updateCustomblockVersion({
        blockId,
        newVersion,
        newParams
      })

      this.$refs.graph.checkNodeWarning(blockId)
      if (this.selectedNodes[0].outgoEdges.length) {
        const sourceNodeOutGo = this.selectedNodes[0].node
        const targetNodeOutGo = this.selectedNodes[0].outgoEdges[0].to
        this.$refs.graph.checkConnectionWarning(
          sourceNodeOutGo,
          targetNodeOutGo,
          true
        )
      }
      if (this.selectedNodes[0].incomeEdges.length > 0) {
        const sourceNodeIncome = this.selectedNodes[0].incomeEdges[0].from
        const targetNodeIncome = this.selectedNodes[0].node
        this.$refs.graph.checkConnectionWarning(
          sourceNodeIncome,
          targetNodeIncome,
          true
        )
      }
      this.$refs.graph.updateData()
    },
    async versionChange({ blockId, newVersion }) {
      const targetBlock = this.edit.layers.find((x) => x.id === blockId)
      this.$emit('fetch-customblock-detail', {
        blockId,
        customBlockId: targetBlock.customblock_id,
        newVersion
      })
    },
    forceUpdateRecipe() {
      this.$refs.graph.updateData()
      this.$emit('update', this.edit)
    },
    removeEdge(edgeId) {
      this.$refs.graph.removeEdge(edgeId)
    },
    removeNode(nodeId) {
      this.$refs.graph.removeNode(nodeId)
    }
  }
}
</script>
<style lang="scss" scoped>
.body-wrap {
  position: relative;
  overflow: hidden;
  display: grid;

  grid-template-areas: 'l-side main r-side';
  grid-template-columns: adjustVW(304) 1fr adjustVW(304);
  grid-template-rows: 1fr;
  width: 100%;
  height: 100%;
  background: #f4f8ff;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Ccircle%20cx%3D%221%22%20cy%3D%221%22%20r%3D%221%22%20style%3D%22fill%3A%23e2e2e2%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: space;
  background-position: adjustVW(16);
  background-size: adjustVW(16);
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-main;

  .graph {
    padding: $space-small;
    grid-area: main;
  }
  .l-side {
    overflow: hidden;
    background: $background;
    box-shadow: $box-shadow-main;
    grid-area: l-side;
  }
  .r-side {
    overflow: hidden;
    background: $background;
    box-shadow: $box-shadow-main;
    grid-area: r-side;
  }
}
</style>
