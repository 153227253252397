<template>
  <div class="c-wrap">
    <sidebar-train
      v-if="!notFound || loading"
      v-bind="sidebar"
      :incorrectOrder="incorrectOrder"
      :projectInfo="projectInfo"
      :progressTraining="progressTraining"
      @not-prevent="$emit('not-prevent')"
    />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="loading" />
          <page-not-found v-else-if="notFound" />
          <recipe-edit
            v-else
            :recipe="recipe"
            :recipeFrom="recipeFrom"
            :recipeLayers="recipeLayers"
            :accountInfo="accountInfo"
            :customblockList="customblockList"
            :customblockDetail="customblockDetail"
            :loadingCustomblockDetail="loadingCustomblockDetail"
            :customblockVersions="customblockVersions"
            :customblockVersionParams="customblockVersionParams"
            isTrain
            @load-detail="$emit('load-detail', $event)"
            @fetch-version-list="$emit('fetch-version-list', $event)"
            @fetch-customblock-detail="
              $emit('fetch-customblock-detail', $event)
            "
            @save-recipe="$emit('save-recipe', $event)"
            @drop-customblock="$emit('drop-customblock', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu />
    <recipe-edit-popup
      v-bind="popup"
      :recipeLayers="recipeLayers"
      :disableClick="disableClick"
      :customblockList="customblockList"
      @close-modal="$emit('close-modal', $event)"
      @input-create-form="$emit('input-create-form', $event)"
      @save-recipe="$emit('save-recipe-confirm', $event)"
      @not-prevent="$emit('not-prevent')"
    />
  </div>
</template>

<script>
import sidebarTrain from '@/components/organisms/sidebar-train.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import recipeEdit from '@/components/organisms/recipe-edit/recipe-edit-main.vue'
import recipeEditPopup from '@/components/organisms/recipe-edit/recipe-edit-popup.vue'
import TransitionPage from '@/components/molecules/transition-page.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import Loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import pageNotFound from '@/components/organisms/page-not-found'

export default {
  components: {
    sidebarTrain,
    Sidebar,
    headerTabs,
    hoverMenu,
    recipeEdit,
    recipeEditPopup,
    TransitionPage,
    TransitionToggleContents,
    Loading,
    loadingProcessing,
    pageNotFound
  },
  props: {
    sidebar: Object,
    headerTabs: Object,
    popup: Object,
    recipe: Object,
    recipeLayers: Object,
    recipeFrom: Object,
    loading: Boolean,
    accountInfo: Object,
    projectInfo: Object,
    progressTraining: Object,
    notFound: Boolean,
    disableClick: Boolean,
    incorrectOrder: Boolean,
    customblockList: Array,
    customblockDetail: Object,
    loadingCustomblockDetail: Boolean,
    customblockVersions: Array,
    customblockVersionParams: Object
  }
}
</script>
<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  background: $background;
}
.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background-color: $bg-train;
}
</style>
