<template>
  <div class="blank-wrap">
    <div class="list">
      <texts
        class="text"
        size="small"
        color="gray"
        :text="$t('recipe.marketplaceBlankText1')"
      />
      <texts
        class="text"
        size="small"
        color="gray"
        :text="$t('recipe.marketplaceBlankText2')"
      />
      <texts
        class="text"
        size="small"
        color="gray"
        :text="$t('recipe.marketplaceBlankText3')"
      />
    </div>
    <button-main
      class="button"
      type="sub"
      :text="$t('recipe.goMarketplace')"
      @click="goMarketplace"
    />
  </div>
</template>
<script>
import buttonMain from '@/components/atoms/button-main'

export default {
  components: {
    buttonMain
  },
  methods: {
    goMarketplace() {
      const url =
        location.protocol + '//marketplace.' + location.host + '/products/' // 商品一覧ページに遷移する
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.blank-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-right: $space-base;
}
.list {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-sub;
  margin-bottom: $space-medium;
}
.text {
  white-space: pre-line;
}
.button {
  width: 100%;
}
</style>
