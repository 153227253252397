<template>
  <div class="c-wrap">
    <sidebar-project-detail
      v-if="!notFoundProject || loading"
      v-bind="sidebar"
    />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="loading" />
          <page-not-found v-else-if="notFoundProject" />
          <recipe-edit
            v-else
            :recipe="recipe"
            :recipeFrom="editRecipeFrom"
            :loading="detailLoading"
            :recipeLayers="recipeLayers"
            :accountInfo="accountInfo"
            :customblockList="customblockList"
            :loadingCutomblockList="loadingCutomblockList"
            :customblockDetail="customblockDetail"
            :loadingCustomblockDetail="loadingCustomblockDetail"
            :customblockVersions="customblockVersions"
            :customblockVersionParams="customblockVersionParams"
            @load-detail="fetchCustomblockDetail"
            @save-recipe="clickSaveRecipe"
            @drop-customblock="dropCustomblock"
            @fetch-version-list="versionSelectOpen"
            @fetch-customblock-detail="fetchCustomBlockVersionParams"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu />
    <recipe-edit-popup
      v-bind="popup"
      :recipeLayers="recipeLayers"
      :disableClick="disableClick"
      :customblockList="customblockList"
      @close-modal="closePopup"
      @input-create-form="inputCreateForm($event)"
      @save-recipe="saveRecipe"
    />

    <prevent-leave
      ref="preventLeave"
      v-model="isPrevent"
      :cautionTexts="$t('recipeEdit.notSaveLeave')"
    />
  </div>
</template>
<script>
import setMountedTimer from '@/mixin/set-mounted-timer'
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import recipeEdit from '@/components/organisms/recipe-edit/recipe-edit-main.vue'
import recipeEditPopup from '@/components/organisms/recipe-edit/recipe-edit-popup.vue'
import TransitionPage from '@/components/molecules/transition-page.vue'
import PreventLeave from '@/components/molecules/prevent-leave.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import Loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import pageNotFound from '@/components/organisms/page-not-found'

// import { splitFullId } from '@/lib/misc.js'
import { mapActions, mapGetters } from 'vuex'
import { recipeValidator } from '@/lib/validator/recipe.js'
import { checkExpiredEducation } from '@/lib/misc.js'
import { initCustomblockParams } from '@/lib/customblock/initBlock'

export default {
  components: {
    sidebarProjectDetail,
    Sidebar,
    headerTabs,
    hoverMenu,
    recipeEdit,
    recipeEditPopup,
    TransitionPage,
    PreventLeave,
    TransitionToggleContents,
    Loading,
    loadingProcessing,
    pageNotFound
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      vm.fetchLayers()

      function f() {
        if (vm.recipeLayers) {
          vm.loadDetail()
        } else {
          setTimeout(f, 500)
        }
      }

      f()

      vm.fetchCustomblocks()

      if (vm.projectId) vm.loadRecipeList(vm.projectId)
      else vm.loadRecipeList()
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.loadDetail()
    this.loadRecipeList()
    this.fetchCustomblocks()
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isPrevent && this.checkEditable) {
      const result = await this.$refs.preventLeave.$confirm()
      if (result) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  methods: {
    ...mapActions('recipes', ['addRecipe', 'fetchLayers', 'loadRecipeList', 'setEditRecipe', 'updateRecipe']),
    ...mapActions('customblock', ['fetchCustomblocks']),

    async loadDetail() {
      if (this.editRecipe) {
        this.recipe = this.editRecipe
      } else {
        const layersInfo = {
          inputData: { id: 0, position: { x: 150, y: 100 } },
          acc: { id: 1, position: { x: 350, y: 200 } }
        }
        const initialLayers = []
        for (const k in layersInfo) {
          const l = JSON.parse(JSON.stringify(this.recipeLayers[k]))
          l.graph = {}
          l.graph.position = layersInfo[k].position
          l.id = layersInfo[k].id
          initialLayers.push(l)
        }

        this.recipe = {
          name: '',
          description: '',
          body: {
            edges: [],
            layers: initialLayers,
            info: {
              name: '',
              description: '',
              graph: {
                pan: { x: 0, y: 0 },
                zoom: 1
              }
            }
          }
        }
      }
      this.detailLoading = false
    },
    showPopup(e) {
      if (!this.popup.showPopup.find((list) => list === e)) {
        this.popup.showPopup.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    clickSaveRecipe(e) {
      this.popup.saveRecipe = e
      this.showPopup('saveRecipe')
    },
    inputCreateForm: function (obj) {
      const { type, form } = obj
      const targetRecipeList = JSON.parse(JSON.stringify(this.recipeList))
      if (this.editRecipeFrom) {
        delete targetRecipeList[
          this.editRecipeFrom.id + '-' + this.editRecipeFrom.accountId
        ]
      }

      if (type === 'recipe') {
        this.popup.createInfo.recipeFormValidate = recipeValidator(
          targetRecipeList,
          form
        )
      }
    },
    saveRecipe: async function (recipeBody) {
      this.submitSave = true
      let res
      try {
        if (this.editRecipeFrom) {
          res = await this.updateRecipe({
            accountId: this.editRecipeFrom.accountId,
            projectId: this.projectId,
            recipe: recipeBody,
            recipeId: this.editRecipeFrom.id
          })
        } else {
          res = await this.addRecipe({
            projectId: this.projectId,
            recipe: recipeBody
          })
        }
      } finally {
        this.submitSave = false
      }
      if (res.status !== 'error') {
        this.closePopup('saveRecipe')
        this.isPrevent = false
        // 一覧から来ている場合は詳細画面に遷移。ただしeditにブラウザバックで戻れないようにreplace
        if (this.projectId) {
          this.$router.replace({
            name: 'recipeProjectDetail',
            params: {
              projectId: this.projectId,
              id: res.detail.id + '-' + res.detail.accountId
            }
          })
        } else {
          this.$router.replace({
            name: 'recipeDetail',
            params: { id: res.detail.id + '-' + res.detail.accountId }
          })
        }
      }
    },
    async fetchCustomblockDetail({ customBlockId, version }) {
      if (this.customblockDetail?.customblock_id === customBlockId) return
      this.loadingCustomblockDetail = true
      try {
        const res = await this.$sendMessageAndReceive({
          action: 'getCustomBlockDetail',
          customBlockId,
          version
        })
        this.customblockDetail = res.result
      } finally {
        this.loadingCustomblockDetail = false
      }
    },
    async dropCustomblock({ customBlockId, version, callback }) {
      const res = await this.$sendMessageAndReceive({
        action: 'getCustomBlockDetail',
        customBlockId,
        version
      })
      const result = res.result
      // graph.vueの処理を再開する
      callback(result)
    },
    async versionSelectOpen({ customBlockId }) {
      const res = await this.$sendMessageAndReceive({
        action: 'getCustomBlockHistory',
        customBlockId
      })
      this.customblockVersions = res.result.map((item) => ({
        name: item.version,
        value: item.version,
        info: item.release_note ?? this.$t('customblock.noReleaseNote')
      }))
    },
    async fetchCustomBlockVersionParams({
      blockId,
      customBlockId,
      newVersion
    }) {
      const res = await this.$sendMessageAndReceive({
        action: 'getCustomBlockDetail',
        customBlockId,
        version: newVersion
      })

      const newTemplateParams = initCustomblockParams(res.result.params)

      this.customblockVersionParams = { blockId, newVersion, newTemplateParams }
    }
  },
  computed: {
    ...mapGetters('recipes', ['editRecipe', 'editRecipeFrom', 'recipeLayers']),
    ...mapGetters('recipes', {
      allRecipeList: 'recipes'
    }),
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('project', ['projectList', 'loadingProjectList']),
    ...mapGetters('customblock', {
      customblockList: 'customblockList',
      loadingCutomblockList: 'loading'
    }),

    loading() {
      return this.detailLoading || this.loadingProjectList
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    projectId() {
      if (this.$route.params.projectId) {
        return parseInt(this.$route.params.projectId)
      }
      return null
    },
    recipeList() {
      if (this.projectId) return this.allRecipeList
      else return []
    },
    disableClick() {
      return this.submitSave
    },
    checkEditable() {
      if (this.accountInfo.plan === 'free') return false
      if (checkExpiredEducation(this.accountInfo)) return false
      return true
    }
  },
  data() {
    return {
      headerTabs: {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 1
      },
      recipe: null,
      detailLoading: true,
      popup: {
        createInfo: {
          recipeFormValidate: {
            duplicate: null
          }
        },
        saveRecipe: null,
        showPopup: []
      },
      sidebar: {
        // サイドバーに表示する情報
        project: {
          name: '退職予測',
          description:
            'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
        },
        activeLink: 'recipe'
      },
      isPrevent: true,
      submitSave: false,
      customblockDetail: null,
      loadingCustomblockDetail: false,

      customblockVersions: [],
      customblockVersionParams: {}
    }
  }
}
</script>
<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  background: $background;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background: $background-sub;
}
</style>
