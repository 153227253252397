<template>
  <div class="recipe-detail">
    <div class="top">
      <recipe-detail-top
        :accountInfo="accountInfo"
        :disabledDelete="disabledDelete"
        :isTrain="isTrain"
        :recipe="recipe"
        :customblockList="customblockList"
        @show-delete-popup="$emit('show-delete-popup')"
        @select-recipe="$emit('select-recipe', $event)"
        @page-back="$emit('page-back')"
      />
    </div>
    <!--main-->
    <div v-if="recipe" class="body">
      <loading v-if="!recipeLayers || loadingCutomblockList" />
      <recipe-detail-body
        v-else
        :recipe="recipe"
        :recipeLayers="recipeLayers"
        :customblockList="customblockList"
        :customblockDetail="customblockDetail"
        :loadingCustomblockDetail="loadingCustomblockDetail"
        @load-detail="$emit('load-detail', $event)"
      />
    </div>
    <!--main-->
    <div v-if="recipe" class="side">
      <recipe-detail-side
        :recipe="recipe"
        :recipeFormValidate="editInfo.recipeFormValidate"
        @input-edit-form="$emit('input-edit-form', $event)"
        @save-recipe-detail="$emit('save-recipe-detail', $event)"
      />
    </div>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading.vue'
import recipeDetailBody from './recipe-detail-body'
import recipeDetailSide from './recipe-detail-side'
import recipeDetailTop from './recipe-detail-top'

export default {
  components: {
    loading,
    recipeDetailBody,
    recipeDetailSide,
    recipeDetailTop
  },
  data() {
    return {
      checked: [],
      query: ''
    }
  },
  props: {
    accountInfo: Object,
    disabledDelete: Boolean,
    editInfo: Object,
    isTrain: Boolean,
    loading: Boolean,
    recipe: Object,
    recipeLayers: Object,

    customblockList: Array,
    loadingCutomblockList: Boolean,
    customblockDetail: Object,
    loadingCustomblockDetail: Boolean
  }
}
</script>

<style lang="scss" scoped>
.recipe-detail {
  display: grid;
  grid-template-areas:
    'top top'
    'main side';
  grid-template-columns: adjustVW(1224) auto;
  grid-template-rows: auto 1fr;
  grid-column-gap: $space-medium;
  grid-row-gap: $space-base;
  height: 100vh - adjustVH($headerTabHeight);
  padding: 0 $space-medium $space-medium;

  .top {
    grid-area: top;
  }
  .body {
    overflow: hidden;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    grid-area: main;
  }
  .side {
    overflow: hidden;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    grid-area: side;
  }
}
</style>
