<template>
  <page-top
    class="top-main"
    :title="title"
    iconName="backward"
    notScroll
    isButton
    :isTrain="isTrain"
    @click="$emit('page-back')"
  >
    <div class="top-function-wrap">
      <div class="top-function-button-block">
        <template v-if="isTrain">
          <button-main
            :text="$t('recipeDetail.selectRecipe')"
            type="emphasis"
            @click="selectRecipe()"
          />
        </template>
        <template v-if="checkEditable">
          <button-main
            :text="$t('recipeDetail.editRecipe')"
            :type="isTrain ? 'sub' : 'emphasis'"
            @click="edit()"
          />
          <button-main
            :text="$t('recipeList.menu.copyNewRecipe')"
            type="sub"
            @click="copyNew()"
          />
        </template>
        <template v-else>
          <button-main :text="$t('recipeDetail.editRecipe')" type="disabled" />
          <button-main
            :text="$t('recipeList.menu.copyNewRecipe')"
            type="disabled"
          />
          <div class="top-function-caution">
            <texts class="top-function-caution-text" color="caution" size="min">
              <div v-if="accountInfo.plan === 'free'">
                {{ $t('recipeDetail.caution.free.first') }}
                <router-link
                  :to="{ name: 'changePlan' }"
                  class="top-function-caution-link"
                >
                  {{ $t('recipeDetail.caution.free.link') }}
                </router-link>
                {{ $t('recipeDetail.caution.free.last') }}
              </div>
              <div v-else>
                {{ $t('recipeDetail.caution.education.first') }}
                <router-link
                  :to="{ name: 'changePlan' }"
                  class="top-function-caution-link"
                >
                  {{ $t('recipeDetail.caution.education.link') }}
                </router-link>
                {{ $t('recipeDetail.caution.education.last') }}
              </div>
            </texts>
          </div>
        </template>
        <template
          v-if="checkCustomblockAvailable && accountInfo.plan === 'basic'"
        >
          <div class="top-function-caution">
            <texts
              class="top-function-caution-text"
              :text="$t('common.disabled.NOT_AVAILABLE_CUSTOMBLOCK_FOR_RECIPE')"
              color="caution"
              size="min"
            />
          </div>
        </template>
      </div>
      <text-menu-list
        class="top-function-menu"
        :menus="iconMenus"
        @menu-click="$emit('show-delete-popup')"
      />
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import ButtonMain from '@/components/atoms/button-main.vue'
import texts from '@/components/atoms/text'
import textMenuList from '@/components/molecules/text-menu-list'
import { checkExpiredEducation } from '@/lib/misc.js'
import { mapActions } from 'vuex'

export default {
  components: {
    pageTop,
    ButtonMain,
    texts,
    textMenuList
  },
  props: {
    recipe: Object,
    accountInfo: Object,
    isTrain: Boolean,
    disabledDelete: Boolean,
    customblockList: Array
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions('recipes', ['setEditRecipe']),
    edit() {
      const projectId = this.$route.params?.projectId
      this.setEditRecipe({
        recipe: this.recipe,
        from: {
          id: this.recipe.id,
          accountId: this.recipe.accountId
        }
      })
      if (!this.isTrain) {
        if (projectId) {
          this.$router.push({
            name: 'recipeProjectEdit'
          })
        } else {
          this.$router.push({
            name: 'recipeEdit'
          })
        }
      } else {
        this.$router.push({
          name: 'trainRecipeEdit'
        })
      }
    },
    copyNew() {
      const projectId = this.$route.params?.projectId
      this.setEditRecipe({
        recipe: this.recipe,
        from: null
      })
      if (!this.isTrain) {
        if (projectId) {
          this.$router.push({
            name: 'recipeProjectEdit'
          })
        } else {
          this.$router.push({
            name: 'recipeEdit'
          })
        }
      } else {
        this.$router.push({
          name: 'trainRecipeEdit'
        })
      }
    },
    selectRecipe() {
      this.$emit('select-recipe', this.recipe)
    }
  },
  computed: {
    title() {
      return this.recipe?.name
    },
    iconMenus() {
      return [
        {
          id: 1,
          name: this.$t('common.deleteButton'),
          iconName: 'delete',
          value: 'delete',
          disabled: this.disabledDelete,
          disabledTips: this.$t(
            'common.disabled.organization.otherAccountItemDelete'
          )
        }
      ]
    },
    checkEditable() {
      if (this.accountInfo.plan === 'free') return false
      if (checkExpiredEducation(this.accountInfo)) return false
      return true
    },
    checkCustomblockAvailable() {
      if (this.customblockList == null || this.customblockList.length === 0)
        return false
      const layers = this.recipe.body.layers
      const usedcustomblocks =
        layers.filter((layer) => {
          return layer.name === 'customblock'
        }) ?? []
      if (usedcustomblocks.length === 0) return false
      const checkCustomblock = usedcustomblocks.some((target) => {
        return !this.customblockList.some((customblock) => {
          return customblock.customblock_id === target.customblock_id
        })
      })
      return checkCustomblock
    }
  }
}
</script>
<style lang="scss" scoped>
.top-function {
  &-wrap {
    position: relative;
    display: flex;
    align-items: center;
  }
  &-main {
    display: flex;
  }
  &-button {
    margin: 0 $space-medium 0 0;
  }
  &-search {
    width: adjustVW(320);
  }
  &-menu {
    margin-left: auto;
  }
  &-button-block {
    display: flex;
    align-items: center;
  }
  &-caution {
    margin-left: $space-large;
    &-text {
      white-space: pre-line;
    }
    &-link {
      color: $text-caution;
      text-decoration: underline;
    }
  }
}
</style>
