<template>
  <div>
    <prevent-leave
      ref="preventLeave"
      v-model="isPrevent"
      :title="$t('common.trainLeave.popup.title')"
      :saveButton="
        $t('common.trainLeave.popup.saveButton', { currentPage: currentPage })
      "
      :leaveButton="$t('common.trainLeave.popup.leaveButton')"
      leaveButtonColor="sub"
      @save-event="closePrevent"
    >
      <texts class="prevent-leave-text" isShowAll size="small">
        <span
          v-html="
            $t('common.trainLeave.reason.first', {
              leavePage: leavePage,
              currentPage: currentPage
            })
          "
        />
        <span
          v-html="
            $t('common.trainLeave.reason.second', { currentPage: currentPage })
          "
        />
        <span v-html="$t('common.trainLeave.reason.third')" />
      </texts>
    </prevent-leave>
    <recipe-detail
      isTrain
      :body="body"
      :popup="popup"
      :projectInfo="projectInfo"
      :progressTraining="progressTraining"
      :sidebar="sidebar"
      :headerTabs="headerTabs"
      :recipe="recipe"
      :recipeLayers="recipeLayers"
      :detailLoading="detailLoading"
      :accountInfo="accountInfo"
      :showPopup="popup.showPopup"
      :loading="loading"
      :notFound="notFound"
      :disableClick="disableClick"
      :incorrectOrder="incorrectOrder"
      :customblockList="customblockList"
      :loadingCutomblockList="loadingCutomblockList"
      :customblockDetail="customblockDetail"
      :loadingCustomblockDetail="loadingCustomblockDetail"
      @load-detail="fetchCustomblockDetail"
      @input-edit-form="inputEditForm($event)"
      @save-recipe-detail="saveRecipeDetail($event)"
      @show-delete-popup="showPopup('deleteRecipe')"
      @close-modal="closePopup($event)"
      @delete-recipe="deleteRecipe()"
      @select-recipe="selectRecipe($event)"
      @not-prevent="notPrevent"
      @restart-prevent="restartPrevent"
      @page-back="pageBack"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { splitFullId } from '@/lib/misc.js'
import { checkTrainingOrder } from '@/lib/progress-training'
import { recipeValidator } from '@/lib/validator/recipe.js'
import { checkReasons } from '@/lib/checkReasons.js'
import {
  checkOuterTraining,
  checkCorrectBackPage
} from '@/lib/training-pages-correct-behavior'
import setMountedTimer from '@/mixin/set-mounted-timer'

import recipeDetail from '@/components/templates/train/recipe-detail'
import preventLeave from '@/components/molecules/prevent-leave'

export default {
  components: {
    recipeDetail,
    preventLeave
  },
  mixins: [setMountedTimer],
  async beforeRouteLeave(to, from, next) {
    this.isPrevent = this.checkPrevent(to.name)
    if (this.isPrevent) {
      this.leavePageName = to.name
      const result = await this.$refs.preventLeave.$confirm()
      if (result) {
        this.deleteProgressTraining({ projectId: this.projectId })

        this.setProgressTraining({ item: this.projectId, setType: 'project' })
        this.setProgressTraining({ item: this.projectId, setType: 'project' })

        this.$router.push({
          name: 'projectDetail',
          params: {
            projectId: this.projectId
          }
        })
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      vm.loadDetail(to.params.id)

      await vm.setModelSettingInfo(parseInt(to.params.projectId))
      vm.incorrectOrder = !checkTrainingOrder({
        projectId: vm.projectId,
        stage: 'recipe',
        progressList: vm.modelSettingList
      })
      await vm.fetchLayers()

      if (vm.incorrectOrder) {
        vm.showPopup('preventTrainingStatus')
      }
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.loadDetail(to.params.id)
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('datasets', ['datasetLoading']),
    ...mapGetters('project', ['projectList', 'loadingProjectList']),
    ...mapGetters('recipes', ['recipes', 'recipeLayers']),
    ...mapGetters('trainings', ['predictionColumnOptions']),
    ...mapGetters('models', ['modelSettingList']),
    ...mapGetters('customblock', {
      customblockList: 'customblockList',
      loadingCutomblockList: 'loading'
    }),
    ...mapGetters('trainingDataset', ['trainingDataset']),

    projectId() {
      return parseInt(this.$route.params.projectId)
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    notFoundRecipe() {
      return !this.recipe
    },
    notFound() {
      return this.notFoundProject || this.notFoundRecipe
    },
    loading() {
      return (
        this.detailLoading ||
        this.loadingProjectList ||
        this.loadingDatasetDetail
      )
    },
    projectInfo() {
      if (this.projectId) {
        return this.projectList[this.projectId]
      }
      return null
    },
    sidebar() {
      return {
        // サイドバーに表示する情報
        activeLink: 'recipeSetting',
        status: 'confirm'
      }
    },
    datasetId() {
      if (!this.progressTraining) return null
      if (this.progressTraining.preprocessingDatasetId) {
        return this.progressTraining.preprocessingDatasetId
      } else {
        return this.progressTraining.datasetId
      }
    },
    disableClick() {
      return this.submitDeleting
    },
    recipeFullId: function () {
      return this.$route.params.id
    },
    recipeId: function () {
      const recipeId = splitFullId(this.$route.params.id)[0]
      return recipeId
    },
    leavePage() {
      return this.$t('common.trainLeave.titles.' + this.leavePageName)
    },
    currentPage() {
      return this.$t('common.trainLeave.titles.' + this.currentPageName)
    },
    disabledPreprocessingStructuredData() {
      if (!this.datasetId || !this.datasetList) return false
      const target = this.datasetList[this.datasetId]
      return (
        target?.type === 'structured' ||
        target?.accountId !== this.accountInfo.accountId
      )
    },
    datasetList() {
      const result = {}
      this.projectList[this.projectId]?.listData?.forEach((item) => {
        result[item.id] = item
      })
      return result
    }
  },
  methods: {
    ...mapActions('recipes', ['deleteRecipes', 'loadRecipeList', 'fetchLayers']),
    ...mapActions('trainings', ['fetchValidColumns']),
    ...mapActions('models', ['deleteProgressTraining', 'getProgressTraining', 'setProgressTraining']),

    async loadDetail(fullId) {
      this.detailLoading = true

      const [recipeId, recipeAccountId] = splitFullId(fullId)
      const res = await this.$sendMessageAndReceive({
        action: 'getRecipeDetail',
        recipe_id: recipeId,
        recipe_account_id: recipeAccountId
      })
      this.recipe = res.detail

      await this.loadRecipeList(this.projectId)

      this.detailLoading = false
    },
    async deleteRecipe() {
      this.submitDeleting = true
      const recipe = [
        {
          id: this.recipe.id,
          accountId: this.recipe.accountId
        }
      ]
      try {
        await this.deleteRecipes(recipe)
      } finally {
        this.submitDeleting = false
      }
      this.$router.push({
        name: 'trainRecipeList',
        projectId: this.$route.params.projectId
      })
    },
    async saveRecipeDetail(e) {
      const [recipeId, recipeAccountId] = splitFullId(this.$route.params.id)
      await this.$sendMessageAndReceive({
        action: 'updateRecipeInfo',
        recipe_id: recipeId,
        recipe_account_id: recipeAccountId,
        name: e.name,
        description: e.description
      })
      this.detailLoading = true
      const res = await this.$sendMessageAndReceive({
        action: 'getRecipeDetail',
        recipe_id: recipeId,
        recipe_account_id: recipeAccountId
      })
      this.recipe = res.detail
      this.detailLoading = false
    },
    showPopup(e) {
      // ポップアップを表示
      if (this.popup.showPopup.length > 0) {
        this.popup.showPopup = []
        this.popup.showPopup.push(e)
      } else {
        this.popup.showPopup.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    async selectRecipe(recipe) {
      if (this.progressTraining && !this.datasetLoading) {
        this.validColumnCheck = await this.checkValidColumn()
        if (!this.trainingDataset) {
          this.loadingDatasetDetail = true
          this.checkTemplate = Object.values(this.recipes).find(
            (item) => item.id === recipe.id
          ).fromTemplate
          this.loadingDatasetDetail = false
        }

        this.popup.selectRecipeErrors = checkReasons.bind(this)()

        if (this.popup.selectRecipeErrors.length > 0) {
          this.showPopup('selectRecipeError')
          return
        }

        this.correctTransition = true

        this.setProgressTraining({
          item: recipe.id,
          setType: 'recipe',
          projectId: this.projectId
        })
        this.setProgressTraining({
          item: null,
          setType: 'tmpRecipe',
          projectId: this.projectId
        })

        this.$router.push({
          name: 'training',
          params: { projectId: this.projectId }
        })
      }
    },
    selectedLearningDataType() {
      if (!this.trainingDataset) return null
      let dataType = null
      if (this.trainingDataset.nRows > 0) {
        if (dataType != null) return null
        dataType = 'figures'
      }
      if (this.trainingDataset.nImages > 0) {
        if (dataType != null) return null
        dataType = 'images'
      }
      if (this.trainingDataset.nTexts > 0) {
        if (dataType != null) return null
        dataType = 'texts'
      }
      return dataType
    },
    setModelSettingInfo: async function (projectId) {
      if (projectId) {
        this.progressTraining = await this.getProgressTraining({
          projectId
        })
      } else {
        this.progressTraining = null
      }
    },
    inputDataType() {
      if (!this.recipe) return null
      const input = this.recipe.body.layers.find((x) => x.name === 'inputData')
      return input?.params?.dataType?.value
    },
    recipeNumClassMatch() {
      if (!this.recipe) return null
      if (!this.trainingDataset) return null
      if (!this.trainingDataset.nClasses) return null
      const body = this.recipe.body
      if (!body) return null
      const layers = body.layers
      let nClass = null
      layers.forEach((v) => {
        if (v.name === 'inputLabels') {
          nClass = v.params.nClass.value
        }
      })
      return nClass === this.trainingDataset.nClasses
    },
    inputEditForm: async function (obj) {
      const { type, form } = obj

      if (type === 'recipe') {
        const recipeKeys = Object.keys(this.recipes)
        const recipes = recipeKeys
          .filter((key) => key !== this.recipeFullId)
          .reduce((result, key) => {
            result[key] = this.recipes[key]
            return result
          }, {})

        this.body.editInfo.recipeFormValidate = recipeValidator(recipes, form)
      }
    },
    async checkValidColumn() {
      const datasetType = this.selectedLearningDataType()
      if (datasetType === 'figures') {
        const req = {
          data_id: this.datasetId,
          data_account_id: this.datasetList[this.datasetId].accountId,
          recipe_id: this.recipe.id,
          recipe_account_id: this.recipe.accountId
        }
        await this.fetchValidColumns(req)
        return this.progressTraining.targetColumn.map((item) => {
          return {
            ...this.predictionColumnOptions[item],
            columnName: item
          }
        })
      } else {
        return { valid: true }
      }
    },
    notPrevent() {
      this.correctTransition = true
    },
    restartPrevent() {
      this.correctTransition = false
    },
    checkPrevent(nextPageName) {
      const datasetType = {
        unstructured: this.progressTraining?.unstructuredDataset,
        disabledPreprocessing: this.disabledPreprocessingStructuredData
      }
      return (
        !checkOuterTraining(nextPageName) &&
        !checkCorrectBackPage(
          'recipe',
          nextPageName,
          this.progressTraining?.laterSelectTargetColumn,
          datasetType
        ) &&
        !this.correctTransition
      )
    },
    closePrevent() {
      this.$refs.preventLeave.closePopup()
    },
    pageBack() {
      this.$router.push({
        name: 'trainRecipeList',
        params: {
          projectId: this.projectId
        }
      })
    },
    async fetchCustomblockDetail({ customBlockId, version }) {
      this.loadingCustomblockDetail = true
      try {
        const res = await this.$sendMessageAndReceive({
          action: 'getCustomBlockDetail',
          customBlockId,
          version
        })
        this.customblockDetail = res.result
      } finally {
        this.loadingCustomblockDetail = false
      }
    }
  },
  data() {
    return {
      headerTabs: {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 1
      },
      recipe: null,
      detailLoading: false,
      loadingDatasetDetail: false,
      body: {
        editInfo: {
          recipeFormValidate: {
            duplicate: undefined
          }
        }
      },
      popup: {
        showPopup: [],
        selectRecipeErrors: []
      },
      submitDeleting: false,
      validColumnCheck: [],
      isPrevent: false,
      correctTransition: false,
      currentPageName: this.$route.name,
      leavePageName: null,
      checkTemplate: false,
      incorrectOrder: null,
      progressTraining: null,
      customblockDetail: null,
      loadingCustomblockDetail: false
    }
  }
}
</script>

<style lang="scss" scoped>
.prevent-leave-text {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-sub;
  ::v-deep {
    .prevent-leave-text-emphasis {
      color: $text-decoration;
    }
    .prevent-leave-text-caution {
      color: $text-caution;
    }
  }
}
</style>
