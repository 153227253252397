<template>
  <div class="popup-wrap">
    <div class="popup-title-wrap">
      <div class="popup-title">
        <texts :text="recipe.name" isBold />
      </div>
      <div>
        <description :text="recipe.description" />
      </div>
    </div>
    <!-- 使用した学習済みAI 表示できるようになるまでコメントアウト https://trello.com/c/7tsGk4h5/243-%E3%83%AC%E3%82%B7%E3%83%94%E6%AE%8B%E3%82%BF%E3%82%B9%E3%82%AF -->
    <!-- <div class="popup-block">
      <div class="popup-inner">
        <texts class="popup-title" :text="$t('recipeDetail.trainedAIsUsed')" color="gray" size="small"/>
        <div class="popup-list">
          <text-with-icon class="popup-item" v-for="(model, index) in 5" :text='`model.name`' iconName="newTab" :key="index" pos="right"/>
        </div>
      </div>
    </div> -->
    <div class="popup-block">
      <div class="popup-inner">
        <text-with-title :title="$t('common.owner')" :text="recipe.accountId" />
      </div>
    </div>
    <div class="popup-block">
      <div class="popup-inner">
        <text-with-title :title="$t('common.createTime')">
          <a-time :value="recipe.createTime" />
        </text-with-title>
      </div>
      <div class="popup-inner">
        <text-with-title :title="$t('common.updateTime')">
          <a-time :value="recipe.updateTime" />
        </text-with-title>
      </div>
    </div>
    <div class="popup-button-block">
      <div class="popup-button">
        <button-main
          type="sub"
          :text="$t('common.cancel')"
          :disabled="disableClick"
          @click="$emit('cancel')"
        />
      </div>
      <div class="popup-button">
        <button-main
          type="caution"
          :text="$t('common.deleteButton')"
          :disabled="disableClick"
          @click="$emit('delete-recipe')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import description from '@/components/atoms/description'
import buttonMain from '@/components/atoms/button-main'
import textWithTitle from '@/components/molecules/text-with-title'
// import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  name: 'recipeDeletePopup',
  components: {
    texts,
    description,
    buttonMain,
    textWithTitle
    // textWithIcon
  },
  props: {
    recipe: Object,
    disableClick: Boolean
  }
}
</script>

<style lang="scss" scoped>
.popup {
  &-wrap {
    width: adjustVW(720);
  }
  &-block {
    display: flex;
    margin: 0 0 $space-medium;
  }
  &-title {
    margin: 0 0 $space-text;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    width: calc(50% - #{$space-base});
    margin: 0 $space-small $space-base 0;
    &:nth-of-type(2n) {
      margin: 0 0 $space-base;
    }
    &:nth-last-of-type(-n + 2) {
      margin-bottom: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &-inner {
    overflow: hidden;
    width: 100%;
    margin: 0 $space-small 0 0;
    &:last-child {
      margin: 0;
    }
  }
  &-button {
    margin: 0 $space-small 0 0;
    &:last-child {
      margin: 0;
    }
    &-block {
      display: flex;
      justify-content: flex-end;
    }
  }
  &-title-wrap {
    margin: 0 0 $space-medium;
  }
}
</style>
