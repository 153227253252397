<template>
  <div class="recipe-edit">
    <recipe-edit-top
      :recipe="recipe"
      :recipeFrom="recipeFrom"
      :isTrain="isTrain"
      :disableSave="disableSave"
      :warnings="warnings"
      class="top"
      :accountInfo="accountInfo"
      @save="saveClicked"
    />
    <recipe-edit-body
      v-if="recipeLayers != null"
      ref="body"
      class="body"
      :recipe="recipe"
      :recipeLayers="recipeLayers"
      :warnings.sync="warnings"
      :customblockList="customblockList"
      :customblockDetail="customblockDetail"
      :loadingCustomblockDetail="loadingCustomblockDetail"
      :customblockVersions="customblockVersions"
      :customblockVersionParams="customblockVersionParams"
      @load-detail="$emit('load-detail', $event)"
      @drop-customblock="$emit('drop-customblock', $event)"
      @fetch-customblock-detail="$emit('fetch-customblock-detail', $event)"
      @update="updateData"
      @fetch-version-list="$emit('fetch-version-list', $event)"
    />
  </div>
</template>
<script>
import recipeEditTop from './recipe-edit-top'
import recipeEditBody from './recipe-edit-body'

export default {
  components: {
    recipeEditTop,
    recipeEditBody
  },
  data() {
    return {
      warnings: []
    }
  },
  props: {
    recipe: Object,
    recipeFrom: Object,
    recipeLayers: Object,
    accountInfo: Object,
    isTrain: Boolean,
    customblockList: Array,
    customblockDetail: Object,
    loadingCustomblockDetail: Boolean,
    customblockVersions: Array,
    customblockVersionParams: Object
  },
  computed: {
    disableSave() {
      return this.warnings.length > 0
    }
  },
  methods: {
    saveClicked() {
      this.$refs.body.forceUpdateRecipe()
    },
    updateData(e) {
      this.$emit('save-recipe', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.recipe-edit {
  display: grid;
  grid-template-areas:
    'top'
    'body';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-row-gap: $space-base;
  height: 100vh - adjustVH($headerTabHeight);
  padding: 0 $space-medium $space-medium;

  .top {
    grid-area: top;
  }
  .body {
    grid-area: body;
  }
}
</style>
