<template>
  <div>
    <button
      v-if="edit"
      v-tooltip.right="
        layerName === 'TrendFlow' && name === 'seasonality'
          ? $t('recipe.param.TrendFlow.seasonalityAddHelp')
          : null
      "
      class="list-button"
      @click="addListBody"
    >
      <text-with-icons
        iconName="plus"
        :text="$t('recipe.param.' + layerName + '.' + name)"
        size="small"
      />
    </button>
    <transition-group name="add-list" mode="out-in">
      <div
        v-for="(body, valueIndex) in this.value"
        :key="valueIndex + 1"
        class="list-inner"
      >
        <div v-for="(param, name, index) in body" :key="index" class="item">
          <parameter
            v-model="param.value"
            v-tooltip="
              isInput === valueIndex
                ? errorTip(param.value, layerName, name)
                : null
            "
            :layerName="layerName"
            :name="name"
            :edit="edit"
            :options="setOptions(layerName, param.options)"
            :type="param.type"
            :min="param.min"
            :max="param.max"
            :step="1"
            required
            :error="!validate(param.value, layerName, name)"
            @input="$emit('input', value), onInput(valueIndex)"
            @blur="onBlur"
            @focus="onFocus(valueIndex)"
          />
        </div>
        <button
          v-if="edit"
          class="list-button-delete"
          @click="deleteListBody(valueIndex)"
        >
          <text-with-icons
            class="list-button-delete-inner"
            iconName="delete"
            :text="$t('common.deleteButton')"
            size="small"
          />
        </button>
      </div>
    </transition-group>
  </div>
</template>

<script>
import textWithIcons from '@/components/molecules/text-with-icon'
import { badSeasonalityName } from '@/lib/recipe/trendflow.js'

export default {
  name: 'paramPeriod',
  components: {
    textWithIcons,
    Parameter: () =>
      // 循環参照なので、遅延読み込みが必要
      import('@/components/organisms/recipe-edit/sidebar/parameter.vue')
  },
  data() {
    return {
      isInput: null
    }
  },
  props: {
    value: Array,
    layerName: String,
    name: String,
    edit: { type: Boolean, default: true },
    list_body: { type: Object, default: () => {} }
  },
  computed: {
    settedNames() {
      return this.value.map((item) => {
        return item.seasonalityName.value
      })
    }
  },
  methods: {
    errorTip(value, layerName, name) {
      if (layerName === 'TrendFlow' && name === 'seasonalityName') {
        if (value === '') {
          return {
            trigger: 'manual',
            content: this.$t('common.required'),
            show: true,
            autoHide: false,
            hideOnTargetClick: false
          }
        } else if (badSeasonalityName(value)) {
          return {
            trigger: 'manual',
            content: this.$t('common.invalidName'),
            show: true,
            autoHide: false,
            hideOnTargetClick: false
          }
        } else if (
          this.settedNames.filter((item) => {
            return item === value
          }).length > 1
        ) {
          return {
            trigger: 'manual',
            content: this.$t('recipe.editTips.trendFlowSeasonalityName', {
              value
            }),
            show: true,
            autoHide: false,
            hideOnTargetClick: false
          }
        }
        return null
      }
      return null
    },
    validate(value, layerName, name) {
      return this.errorTip(value, layerName, name) == null
    },
    addListBody() {
      const body = JSON.parse(JSON.stringify(this.list_body))
      this.$emit('input', [].concat([body], this.value))
    },
    deleteListBody(index) {
      const newValue = [...this.value]
      newValue.splice(index, 1)
      this.$emit('input', newValue)
    },
    setOptions(layerName, options) {
      const newOptions = []
      if (options) {
        options.forEach((v) => {
          newOptions.push({
            value: v.value,
            text: this.$t(
              'recipe.param.' + layerName + '.' + this.checkValueEscape(v.text)
            )
          })
        })
      }
      return newOptions
    },
    checkValueEscape(value) {
      let target = value
      if (target.includes('.')) {
        target = target.replace(/\./, '_')
      }
      return target
    },
    onInput(index) {
      this.isInput = index
    },
    onFocus(index) {
      this.isInput = index
    },
    onBlur() {
      this.isInput = null
    }
  },
  watch: {
    value() {
      this.isInput = null
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  &-button {
    display: block;
    width: 100%;
    padding: $space-base $space-sub;
    margin: 0 0 $space-small;
    border-radius: 9in;
    transition: background-color $transition-base, opacity $transition-base;
    &:hover {
      background: $background-sub;
      opacity: 0.5;
    }
    &:active {
      background: $background-sub;
      opacity: 1;
    }
    &-delete {
      @extend .list-button;
      width: fit-content;
      margin: 0 0 0 auto;
    }
  }
  &-inner {
    padding: 0 0 $space-small;
    margin: 0 0 $space-small;
    border-bottom: $border-main;
    &:last-of-type {
      border: none;
    }
  }
}

.item {
  margin: 0 0 $space-small;
}
</style>
