<template>
  <div class="side-wrap">
    <template v-if="!recipeEditting">
      <div class="summary">
        <div class="title">
          <texts :text="recipe.name" size="large" />
        </div>
        <div class="desc">
          <description-long :title="recipe.name" :text="recipe.description" />
        </div>
        <button class="edit" @click="recipeEditting = !recipeEditting">
          <text-with-icon
            :text="$t('common.editButton')"
            iconName="edit"
            size="small"
          />
        </button>
      </div>
      <div class="info">
        <div class="item-block">
          <div class="item-half">
            <text-with-title
              class="item-text"
              :text="recipe.accountId"
              :title="$t('common.owner')"
            />
          </div>
          <div class="item-half">
            <text-with-title
              class="item-text"
              :text="recipe.lastUpdater"
              :title="$t('common.lastUpdater')"
            />
          </div>
        </div>
        <!-- 学習済みAIが紐づけられるまでコメントアウト
        <div class="item-block item-block-list">
          <div class="item-title">
            <texts :text="$t('recipeDetail.trainedAIsUsed')" color='gray' size='small'/>
          </div>
          <div class="item-list">
            <div v-for='(item, index) in recipe.trainedAI' :key='index' class="item-list-item">
              <router-link :to="{name: 'trainedAiDetail', params: {id:item.id}}">
                <text-with-icon class="item-text" :text='item.name' iconName="newTab" pos='right' color="link-default"/>
              </router-link>
            </div>
          </div>
        </div>-->
        <div class="item-block">
          <div class="item-half">
            <text-with-title class="item-text" :title="$t('common.createTime')">
              <a-time :value="recipe.createTime" />
            </text-with-title>
          </div>
          <div class="item-half">
            <text-with-title class="item-text" :title="$t('common.updateTime')">
              <a-time :value="recipe.updateTime" />
            </text-with-title>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="edit-wrap">
      <div class="edit-title">
        <input-box-validation
          v-model="editItem.name"
          :accept="validate.accept"
          :error="validate.error"
          :value="recipe.name"
          popupView
          isNameValidation
          :nameValidationSuggests="recipeFormValidate.suggests"
        />
      </div>
      <div class="edit-desc">
        <input-box
          v-model="editItem.description"
          class="edit-desc-inner"
          isTextArea
          isGray
          :value="recipe.description"
        />
      </div>
      <div class="edit-button-list">
        <div class="edit-button-save">
          <button-main
            class="edit-button-save-button"
            type="emphasis"
            :isDisabled="disabledSubmit"
            :text="$t('common.saveButton')"
            @click="saveRecipe"
          />
        </div>
        <div class="edit-button-cancel">
          <button-main
            size="min"
            :text="$t('common.cancel')"
            @click="recipeEditting = !recipeEditting"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import descriptionLong from '@/components/atoms/description-long'
import buttonMain from '@/components/atoms/button-main'
import textWithIcon from '@/components/molecules/text-with-icon'
import textWithTitle from '@/components/molecules/text-with-title'
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation'

export default {
  components: {
    buttonMain,
    descriptionLong,
    inputBox,
    inputBoxValidation,
    texts,
    textWithIcon,
    textWithTitle
  },
  data() {
    return {
      editItem: {
        name: this.recipe.name,
        description: this.recipe.description
      },
      recipeEditting: false,
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    recipe: Object,
    recipeFormValidate: Object
  },
  computed: {
    disabledSubmit: function () {
      if (this.editItem.name === '') return true
      if (this.validate.error) return true

      return false
    }
  },
  watch: {
    editItem: {
      deep: true,
      handler: function (form) {
        this.$emit('input-edit-form', {
          type: 'recipe',
          form
        })
      }
    },
    recipeFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    }
  },
  methods: {
    saveRecipe(e) {
      this.$emit('save-recipe-detail', this.editItem)
      this.recipeEditting = false
    }
  },
  mounted: function () {
    this.$emit('input-edit-form', {
      type: 'recipe',
      form: this.editItem
    })
  }
}
</script>

<style lang="scss" scoped>
.side-wrap {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.summary {
  flex-shrink: 0;
  padding: 0 0 $space-small;
  margin: 0 0 $space-small;
}
.info {
  overflow-y: auto;
  flex-shrink: 1;
  height: 100%;
  @include scrollbar;
}
.title {
  margin: 0 0 $space-base;
}
.desc {
  margin: 0 0 $space-small;
}
.item {
  &-block {
    display: flex;
    margin: 0 0 $space-small;
    &:last-of-type {
      margin: 0;
    }
    &-list {
      flex-direction: column;
    }
  }
  &-half {
    width: calc(50% - #{$space-base});
    margin: 0 $space-small 0 0;
    &:nth-of-type(2n) {
      margin: 0;
    }
  }
  &-list {
    &-item {
      margin: 0 0 $space-text;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &-title {
    margin: 0 0 $space-text;
  }
}
.edit {
  position: relative;
  display: block;
  margin: 0 0 0 auto;
  z-index: 1;
  &-disabled {
    cursor: not-allowed;
  }
  &::after {
    content: '';
    position: absolute;
    top: -$space-text;
    left: -$space-base;
    width: calc(100% + #{$space-base * 2});
    height: calc(100% + #{$space-text * 2});
    background: $medium-gray;
    border-radius: 9in;
    opacity: 0;
    z-index: -1;
    will-change: opacity;
    transition: $transition-base;
  }
  &:hover {
    opacity: 1;
  }
  &:hover::after {
    opacity: 1;
  }
  &-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-title {
    flex-shrink: 0;
    flex-grow: 0;
    margin: 0 0 $space-small;
  }
  &-desc {
    flex-shrink: 1;
    flex-grow: 1;
    height: 100%;
    margin: 0 0 $space-small;
    &-inner {
      height: 100%;
      &::v-deep div {
        height: 100%;
      }
    }
  }
  &-button {
    &-list {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &-save {
      margin: 0 $space-base 0 0;
    }
  }
}
</style>
