var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('prevent-leave',{ref:"preventLeave",attrs:{"title":_vm.$t('common.trainLeave.popup.title'),"saveButton":_vm.$t('common.trainLeave.popup.saveButton', { currentPage: _vm.currentPage }),"leaveButton":_vm.$t('common.trainLeave.popup.leaveButton'),"leaveButtonColor":"sub"},on:{"save-event":_vm.closePrevent},model:{value:(_vm.isPrevent),callback:function ($$v) {_vm.isPrevent=$$v},expression:"isPrevent"}},[_c('texts',{staticClass:"prevent-leave-text",attrs:{"isShowAll":"","size":"small"}},[_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('common.trainLeave.reason.first', {
            leavePage: _vm.leavePage,
            currentPage: _vm.currentPage
          })
        )}}),_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('common.trainLeave.reason.second', { currentPage: _vm.currentPage })
        )}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('common.trainLeave.reason.third'))}})])],1),_c('recipe-detail',{attrs:{"isTrain":"","body":_vm.body,"popup":_vm.popup,"projectInfo":_vm.projectInfo,"progressTraining":_vm.progressTraining,"sidebar":_vm.sidebar,"headerTabs":_vm.headerTabs,"recipe":_vm.recipe,"recipeLayers":_vm.recipeLayers,"detailLoading":_vm.detailLoading,"accountInfo":_vm.accountInfo,"showPopup":_vm.popup.showPopup,"loading":_vm.loading,"notFound":_vm.notFound,"disableClick":_vm.disableClick,"incorrectOrder":_vm.incorrectOrder,"customblockList":_vm.customblockList,"loadingCutomblockList":_vm.loadingCutomblockList,"customblockDetail":_vm.customblockDetail,"loadingCustomblockDetail":_vm.loadingCustomblockDetail},on:{"load-detail":_vm.fetchCustomblockDetail,"input-edit-form":function($event){return _vm.inputEditForm($event)},"save-recipe-detail":function($event){return _vm.saveRecipeDetail($event)},"show-delete-popup":function($event){return _vm.showPopup('deleteRecipe')},"close-modal":function($event){return _vm.closePopup($event)},"delete-recipe":function($event){return _vm.deleteRecipe()},"select-recipe":function($event){return _vm.selectRecipe($event)},"not-prevent":_vm.notPrevent,"restart-prevent":_vm.restartPrevent,"page-back":_vm.pageBack}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }