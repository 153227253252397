<template>
  <div class="wrap">
    <div
      ref="block"
      class="block"
      :class="{
        'block-move': moveStart,
        'block-off': off,
        'block-firefox': firefoxActive,
        customblock: isCustomblock
      }"
      draggable
      @dragstart="start($event)"
      @drag="move($event)"
      @dragend.passive="end($event)"
      @click="clickBlock($event)"
    >
      <div class="icon">
        <recipe-icon :block="block" :isCustomblock="isCustomblock" />
      </div>
      <texts
        class="text"
        :text="translatedName"
        size="min"
        isBold
      />
    </div>
    <div class="bg" />
    <svg
      id="dragImage"
      xmlns="http://www.w3.org/2000/svg"
      width="1"
      height="1"
      viewPort="0 0 120 120"
    >
      <rect
        x="10"
        y="10"
        width="1"
        height="1"
        fill="transparent"
      />
    </svg>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import recipeIcon from '@/components/atoms/recipe-icon.vue'

export default {
  components: {
    texts,
    recipeIcon
  },
  data() {
    return {
      /** ドラッグを始めたかどうか */
      active: false,
      /** ドラッグを終了したかどうか（終了時のアニメーションに使用） */
      off: false,
      /** ドラッグしてマウスを動かし始めたかどうか */
      moveStart: false,
      /** ドラッグしてマウスを動かしたときの初期位置X */
      xPos: 0,
      /** ドラッグしてマウスを動かしたときの初期位置Y */
      yPos: 0,
      /** firefoxでドラッグを開始したかどうか */
      firefoxActive: false
    }
  },
  props: {
    /** ブロック */
    block: Object
  },
  methods: {
    /** ドラッグ開始この時点でDatatransferに値を渡している。上の階層のドロップエリアでDropイベントを取得して渡した値を受け取る */
    start(e) {
      e.stopPropagation()
      const dragImage = document.getElementById('dragImage')
      this.off = false
      this.xPos = e.pageX - this.$refs.block.offsetWidth
      this.yPos = e.pageY - this.$refs.block.offsetHeight
      if (!this.checkFirefox) {
        e.dataTransfer.setDragImage(dragImage, 0, 0)
        this.active = true
      } else {
        this.firefoxActive = true
      }
      e.dataTransfer.effectAllowed = 'copy'
      if (this.isCustomblock) {
        e.dataTransfer.setData('x-matrixflow-layer', 'customblock')
        e.dataTransfer.setData(
          'x-matrixflow-layer-cb-id',
          this.block.customblock_id
        )
        e.dataTransfer.setData(
          'x-matrixflow-layer-cb-version',
          this.block.latest_version
        )
      } else {
        e.dataTransfer.setData('x-matrixflow-layer', this.block.name)
      }
      // e.dataTransfer.setData('type', this.block.type)
      // storybookに乗せるためにmapActionをコメントアウトしています。（storeもStorybookで読ませないといけなくなるため）必要であればこの部分にもstoreをインポートして以下を利用してください。（参照元 /client/components/recipe/draggableButton のdragStartメソッド）
      /* try {
        e.dataTransfer.setData(this.block.type, this.block.name)
        // eslint-disable-next-line no-undef
        window.dataLayer.push({
          event: 'block-select',
          eventLabel: this.block.name
        })
      } catch {
        this.enableFallback()
        e.dataTransfer.setData('Text', this.block.name) // IE11 only support "Text"
      }
      this.log_info('start', e.dataTransfer.getData('Text')) */
    },
    /** ドラッグしている状態 */
    move(e) {
      if (this.active) {
        this.$refs.block.style.top =
          this.yPos -
          (this.yPos - e.pageY + this.$refs.block.offsetHeight / 2) +
          'px'
        this.$refs.block.style.left =
          this.xPos -
          (this.xPos - e.pageX + this.$refs.block.offsetWidth / 2) +
          'px'
        this.moveStart = true
      }
    },
    /** ドラッグを終了 */
    end() {
      this.moveStart = false
      this.xPos = 0
      this.yPos = 0
      this.$refs.block.style.top = 0
      this.$refs.block.style.left = 0
      this.active = false
      if (!this.checkFirefox) {
        this.off = true
      } else {
        this.firefoxActive = false
      }
    },
    /** ブロックをクリックしたときにパラメーターを表示する用 */
    clickBlock(e) {
      this.$emit('block-click', {
        name: this.block.name,
        type: this.block.type
      })
    }
  },
  computed: {
    // Firefoxはdrag時にマウスポインタの位置を取得できないようなので、動作させないために以下を用意しました
    checkFirefox() {
      return window.navigator.userAgent.indexOf('Firefox') !== -1
    },
    isCustomblock() {
      return this.block?.customblock_id != null
    },
    translatedName() {
      if (this.isCustomblock) {
        return this.block.name
      }
      return this.$t('recipe.layerNames.' + this.block.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: 100%;
  height: adjustVW(40);
}
.block {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: adjustVW(264);
  height: adjustVW(40);
  background: $background;
  border-radius: adjustVW(4);
  cursor: move;
  box-shadow: $border-radius-emphasis;
  opacity: 1;
  z-index: 1;
  transition: transform $transition-base, opacity $transition-base;
  &:active {
    transform: rotate(-2deg);
  }
  &-move {
    position: fixed;
    pointer-events: none;
  }
  &-off {
    animation: blockOff 0.3s;
  }
  &-firefox {
    opacity: 0;
  }

  &.customblock {
    box-shadow: $border-radius-customblock;
  }
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: adjustVW(264);
  height: 100%;
  border-image: repeating-linear-gradient(
    0deg,
    $key-color,
    $key-color adjustVW(4),
    transparent adjustVW(4),
    transparent adjustVW(8)
  );
  background-image: repeating-linear-gradient(
      0deg,
      $key-color,
      $key-color adjustVW(4),
      transparent adjustVW(4),
      transparent adjustVW(8)
    ),
    // left
    repeating-linear-gradient(
        90deg,
        $key-color,
        $key-color adjustVW(4),
        transparent adjustVW(4),
        transparent adjustVW(8)
      ),
    // top
    repeating-linear-gradient(
        180deg,
        $key-color,
        $key-color adjustVW(4),
        transparent adjustVW(4),
        transparent adjustVW(8)
      ),
    // right
    repeating-linear-gradient(
        270deg,
        $key-color,
        $key-color adjustVW(4),
        transparent adjustVW(4),
        transparent adjustVW(8)
      ); // bottom
  background-repeat: no-repeat;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: adjustVW(1) 100%, 100% adjustVW(1), adjustVW(1) 100%,
    100% adjustVW(1);
  border-radius: adjustVW(4);
  opacity: 0.5;
  z-index: 0;
}
.icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: adjustVW(40);
  height: adjustVW(40);
  background: $background-decoration;
  border-radius: adjustVW(4) 0 0 adjustVW(4);
  box-shadow: $border-radius-emphasis;
  .customblock & {
    background: $blue-bg;
    box-shadow: $border-radius-customblock;
  }
  .customblock & ::v-deep svg {
    fill: $blue;
  }
}

.text {
  width: calc(100% - #{adjustVW(40)});
  margin: 0 $space-sub;
}

@keyframes blockOff {
  0% {
    position: absolute;
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
