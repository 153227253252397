import { render, staticRenderFns } from "./delete-recipe.vue?vue&type=template&id=c052d040&scoped=true&"
import script from "./delete-recipe.vue?vue&type=script&lang=js&"
export * from "./delete-recipe.vue?vue&type=script&lang=js&"
import style0 from "./delete-recipe.vue?vue&type=style&index=0&id=c052d040&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c052d040",
  null
  
)

export default component.exports