<template>
  <div class="save-wrap">
    <div class="save-main">
      <div class="save-item">
        <input-box-validation
          v-model="name"
          :accept="validate.accept"
          :error="validate.error"
          :title="$t('recipeEdit.recipeTitle')"
          popupView
          isNameValidation
          :nameValidationSuggests="recipeFormValidate.suggests"
        />
      </div>
      <div class="save-item">
        <input-box
          v-model="description"
          isTextArea
          isGray
          :title="$t('common.description')"
        />
      </div>
      <div>
        <button-large
          :isDisabled="disabledSubmit || disableClick"
          :text="$t('recipeEdit.saveNewButton')"
          @click="saveRecipe"
        />
      </div>
    </div>
    <div class="save-detail">
      <div class="save-detail-inner">
        <graph
          ref="graph"
          class="graph"
          :recipeLayers="recipeLayers"
          :graph="recipeBody"
          :edittable="false"
          :customblockList="customblockList"
          isSave
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonLarge from '@/components/atoms/button-large.vue'
import Graph from '@/components/organisms/recipe-detail/editor/graph.vue'
import InputBox from '@/components/molecules/input-box'
import InputBoxValidation from '@/components/molecules/input-box-validation.vue'

export default {
  name: 'SaveRecipePopup',
  components: {
    ButtonLarge,
    Graph,
    InputBox,
    InputBoxValidation
  },
  data() {
    return {
      name: this.recipeBody?.info?.name ?? '',
      description: this.recipeBody?.info?.description ?? '',
      graph: null,
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    recipeBody: Object,
    recipeFormValidate: Object,
    recipeLayers: Object,
    disableClick: Boolean,
    customblockList: Array
  },
  computed: {
    disabledSubmit: function () {
      if (this.name === '') return true
      if (this.validate.error) return true

      return false
    }
  },
  watch: {
    name: function (val) {
      this.$emit('input-create-form', {
        type: 'recipe',
        form: {
          name: val
        }
      })
    },
    recipeFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    }
  },
  methods: {
    saveRecipe() {
      const recipeBody = JSON.parse(JSON.stringify(this.recipeBody))
      recipeBody.info.name = this.name
      recipeBody.info.description = this.description
      this.$emit('save-recipe', recipeBody)
    }
  },
  async mounted() {
    this.$nextTick(() => this.$refs.graph.buildGraph())

    this.$emit('input-create-form', {
      type: 'recipe',
      form: {
        name: this.name
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.save {
  &-wrap {
    display: grid;
    grid-template-columns: minmax(adjustVW(594), 1fr) adjustVW(966);
    grid-column-gap: $space-medium;
    width: 100%;
    height: 100%;
  }
  &-main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &-item {
    margin: 0 0 $space-medium;
  }
  &-detail {
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      padding: $space-small;
      background-color: $background;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Ccircle%20cx%3D%221%22%20cy%3D%221%22%20r%3D%221%22%20style%3D%22fill%3A%23e2e2e2%22%2F%3E%3C%2Fsvg%3E');
      background-repeat: space;
      background-position: adjustVW(16);
      background-size: adjustVW(16);
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
  }
}
</style>
