<!-- ブロックタイプの一覧 -->
<template>
  <div class="body">
    <div class="title">
      <texts :text="$t('recipe.sidebar.blockType')" size="large" />
    </div>
    <div class="inner">
      <div class="list">
        <button
          v-for="(type, index) in layerTypes"
          :key="index"
          class="item"
          @click="$emit('type-select', type)"
        >
          <div class="text">
            <icons
              class="text-icon"
              :iconName="type + 'RecipeType'"
              color="emphasis"
              size="small"
            />
            <texts :text="$t('recipe.layerType.' + type)" size="small" />
          </div>
          <div class="icon">
            <icons iconName="forward" size="min" />
          </div>
        </button>
      </div>
      <div class="search">
        <!-- テキスト入力完了後のみにイベント発火 -->
        <fuse-search
          gray
          :option="option"
          :value.sync="layerNames"
          :title="$t('recipe.sidebar.search.title')"
          :placeholder="$t('recipe.sidebar.search.placeholder')"
          @change="$emit('change-search', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
import fuseSearch from '@/components/molecules/fuse-search'
export default {
  components: {
    texts,
    icons,
    fuseSearch
  },
  data() {
    return {
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
        keys: [
          {
            name: 'searchKey',
            weight: 1
          }
        ],
        distance: 10000
      }
    }
  },
  props: {
    /** ブロックタイプの一覧 */
    layerTypes: Array,
    layerNames: Array
  }
}
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: $space-small $space-base $space-small $space-small;
}
.inner {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  padding-right: $space-base;
  @include scrollbar;
}
.title {
  margin: 0 0 $space-sub;
}
.list {
  margin: 0 0 $space-sub;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 $space-text $space-small;
  margin: 0 0 $space-small;
  border-bottom: $border-main;
  &:last-of-type {
    margin: 0;
  }
}
.text {
  display: flex;
  align-items: center;
  &-icon {
    margin: 0 $space-base 0 0;
  }
}
</style>
