<template>
  <div class="c-wrap">
    <sidebar-project-detail v-if="!notFound || loading" v-bind="sidebar" />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="loading" />
          <page-not-found v-else-if="notFound" />
          <recipe-detail
            v-else
            v-bind="body"
            :accountInfo="accountInfo"
            :disabledDelete="disabledDelete"
            :loading="detailLoading"
            :recipe="recipe"
            :recipeLayers="recipeLayers"
            :customblockList="customblockList"
            :loadingCutomblockList="loadingCutomblockList"
            :customblockDetail="customblockDetail"
            :loadingCustomblockDetail="loadingCustomblockDetail"
            @load-detail="fetchCustomblockDetail"
            @input-edit-form="inputEditForm($event)"
            @save-recipe-detail="saveRecipeDetail($event)"
            @show-delete-popup="showPopup('deleteRecipe')"
            @page-back="pageBack"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu />
    <recipe-detail-popup
      :showPopup="popup.showPopup"
      :recipe="recipe"
      :disableClick="disableClick"
      :customblockList="customblockList"
      @close-modal="closePopup($event)"
      @delete-recipe="deleteRecipe()"
      @input-create-form="inputCreateForm($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { splitFullId } from '@/lib/misc.js'
import { recipeValidator } from '@/lib/validator/recipe.js'
import setMountedTimer from '@/mixin/set-mounted-timer'

import headerTabs from '@/components/organisms/header-tabs.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import recipeDetail from '@/components/organisms/recipe-detail/recipe-detail-main.vue'
import RecipeDetailPopup from '@/components/organisms/recipe-detail/recipe-detail-popup.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import pageNotFound from '@/components/organisms/page-not-found'
import Sidebar from '@/components/organisms/sidebar.vue'
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import TransitionPage from '@/components/molecules/transition-page.vue'

export default {
  components: {
    sidebarProjectDetail,
    Sidebar,
    headerTabs,
    hoverMenu,
    recipeDetail,
    TransitionPage,
    RecipeDetailPopup,
    TransitionToggleContents,
    loading,
    loadingProcessing,
    pageNotFound
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.detailLoading = true
      await vm.$waitConnected()
      await vm.loadDetail(to.params.id)
      await vm.loadRecipeList(vm.projectId)
      await vm.fetchLayers()
      await vm.fetchCustomblocks()
      vm.detailLoading = false
      vm.previousPageName = from.name
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.loadDetail(to.params.id)
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('project', ['projectList', 'loadingProjectList']),
    ...mapGetters('recipes', ['recipes', 'recipeLayers']),
    ...mapGetters('customblock', {
      customblockList: 'customblockList',
      loadingCutomblockList: 'loading'
    }),

    disabledDelete() {
      if (this.accountInfo?.organizationInfo?.role === 'admin') return false
      if (this.accountInfo.accountId !== this.recipe?.accountId) {
        return true
      } else {
        return false
      }
    },
    projectId() {
      if (this.$route.params.projectId) {
        return parseInt(this.$route.params.projectId)
      }
      return null
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    notFoundRecipe() {
      return !this.recipe
    },
    notFound() {
      return this.notFoundProject || this.notFoundRecipe
    },
    loading() {
      return this.detailLoading || this.loadingProjectList
    },
    disableClick() {
      return this.submitDeleting
    },
    recipeFullId: function () {
      return this.$route.params.id
    },
    recipeId: function () {
      const recipeId = splitFullId(this.$route.params.id)[0]
      return recipeId
    }
  },
  methods: {
    ...mapActions('recipes', ['deleteRecipes', 'loadRecipeList', 'fetchLayers']),
    ...mapActions('customblock', ['fetchCustomblocks']),

    async loadDetail(fullId) {
      const [recipeId, recipeAccountId] = splitFullId(fullId)
      const res = await this.$sendMessageAndReceive({
        action: 'getRecipeDetail',
        recipe_id: recipeId,
        recipe_account_id: recipeAccountId
      })
      this.recipe = res.detail
    },
    async deleteRecipe() {
      this.submitDeleting = true
      const recipe = [
        {
          id: this.recipe.id,
          accountId: this.recipe.accountId
        }
      ]
      try {
        await this.deleteRecipes(recipe)
      } finally {
        this.submitDeleting = false
      }
      this.$router.push({
        name: 'recipeList',
        projectId: this.$route.params.projectId
      })
    },
    async saveRecipeDetail(e) {
      const [recipeId, recipeAccountId] = splitFullId(this.$route.params.id)
      await this.$sendMessageAndReceive({
        action: 'updateRecipeInfo',
        recipe_id: recipeId,
        recipe_account_id: recipeAccountId,
        name: e.name,
        description: e.description
      })
      this.detailLoading = true
      const res = await this.$sendMessageAndReceive({
        action: 'getRecipeDetail',
        recipe_id: recipeId,
        recipe_account_id: recipeAccountId
      })
      this.recipe = res.detail
      this.detailLoading = false
    },
    // ポップアップを表示
    showPopup(e) {
      if (this.popup.showPopup.length > 0) {
        this.popup.showPopup = []
        this.popup.showPopup.push(e)
      } else {
        this.popup.showPopup.push(e)
      }
    },
    // ポップアップを閉じる
    closePopup(e) {
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    inputEditForm: async function (obj) {
      const { type, form } = obj

      if (type === 'recipe') {
        const recipeKeys = Object.keys(this.recipes)
        const recipes = recipeKeys
          .filter((key) => key !== this.recipeFullId)
          .reduce((result, key) => {
            result[key] = this.recipes[key]
            return result
          }, {})

        this.body.editInfo.recipeFormValidate = recipeValidator(recipes, form)
      }
    },
    pageBack() {
      if (
        this.previousPageName === 'recipeProjectEdit' ||
        this.previousPageName === 'recipeProjectList'
      ) {
        /**
         * replaceにするとブラウザバックで戻られたときに、listページが複数回表示されることがある。
         * （レシピ詳細→一覧→詳細→一覧で、ブラウザバックを使うと一覧→一覧になってしまう）
         * そのため、間に詳細画面が表示されるように、pushで遷移している
         */
        this.$router.push({
          name: 'recipeProjectList',
          params: {
            projectId: this.projectId
          }
        })
      } else {
        this.$router.go(-1)
      }
    },
    async fetchCustomblockDetail({ customBlockId, version }) {
      this.loadingCustomblockDetail = true
      try {
        const res = await this.$sendMessageAndReceive({
          action: 'getCustomBlockDetail',
          customBlockId,
          version
        })
        this.customblockDetail = res.result
      } finally {
        this.loadingCustomblockDetail = false
      }
    }
  },
  mounted: async function () {
    await this.loadRecipeList(this.projectId)
  },
  data() {
    return {
      headerTabs: {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 1
      },
      sidebar: {
        // サイドバーに表示する情報
        project: {
          name: '退職予測',
          description:
            'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
        },
        activeLink: 'recipe'
      },
      recipe: null,
      detailLoading: false,
      body: {
        editInfo: {
          recipeFormValidate: {
            duplicate: undefined
          }
        }
      },
      popup: {
        showPopup: []
      },
      submitDeleting: false,
      previousPageName: null,
      customblockDetail: null,
      loadingCustomblockDetail: false
    }
  }
}
</script>
<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  background: $background;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background: $background-sub;
}
</style>
