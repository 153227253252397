<template>
  <popup-array
    :popupDefs="popupDefs"
    :showPopup="showPopup"
    :noClose="disableClick"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #saveRecipe>
      <save-recipe
        :recipeBody="saveRecipe"
        :recipeFormValidate="createInfo.recipeFormValidate"
        :recipeLayers="recipeLayers"
        :disableClick="disableClick"
        :customblockList="customblockList"
        @cancel="$emit('close-modal', 'saveRecipe')"
        @close-modal="$emit('close-modal', 'saveRecipe')"
        @input-create-form="$emit('input-create-form', $event)"
        @save-recipe="$emit('save-recipe', $event)"
      />
    </template>
    <template #selectRecipeError>
      <select-recipe-error
        :selectRecipeErrors="selectRecipeErrors"
        @close-modal="$emit('close-modal', 'selectRecipeError')"
      />
    </template>
    <template #preventTrainingStatus>
      <prevent-training-status @not-prevent="$emit('not-prevent')" />
    </template>
  </popup-array>
</template>

<script>
import { popupArray } from '@/components/molecules/popup'
import saveRecipe from './popup/save-recipe'
import selectRecipeError from '../recipe-list/popup/select-recipe-error'
import preventTrainingStatus from '@/components/organisms/common-popups/prevent-training-status.vue'

export default {
  components: {
    saveRecipe,
    popupArray,
    selectRecipeError,
    preventTrainingStatus
  },
  computed: {
    popupDefs() {
      return {
        saveRecipe: {
          title: this.$t('recipeEdit.popup.saveRecipe.title'),
          maxWidth: true,
          maxHeight: true
        },
        selectRecipeError: {
          title: this.$t('recipeDetail.selectRecipeErrors.title')
        },
        preventTrainingStatus: {
          title: this.$t('common.popup.preventTrainingStatus.title')
        }
      }
    }
  },
  props: {
    createInfo: Object,
    recipeLayers: Object,
    saveRecipe: Object,
    selectRecipeErrors: Array,
    showPopup: Array,
    disableClick: Boolean,
    customblockList: Array
  }
}
</script>
