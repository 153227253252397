<template>
  <div class="body-wrap">
    <div class="graph-wrap">
      <Graph
        ref="graph"
        class="graph"
        :graph.sync="graph"
        :recipeLayers="recipeLayers"
        :edittable="false"
        :customblockList="customblockList"
        @select="selectNode($event)"
      />
    </div>
    <div class="recipe-param">
      <recipe-edit-side-params
        :selectedNodes="selectedNodes"
        :customblockDetail="customblockDetail"
        :customblockList="customblockList"
        :loadingCustomblockDetail="loadingCustomblockDetail"
        :editting="false"
        @input="$emit('input', $event)"
        @remove-edge="$emit('remove-edge', $event)"
        @load-detail="$emit('load-detail', $event)"
      />
    </div>
  </div>
</template>
<script>
import Graph from '@/components/organisms/recipe-detail/editor/graph.vue'
import recipeEditSideParams from '@/components/organisms/recipe-edit/recipe-edit-side-params'

export default {
  components: {
    Graph,
    recipeEditSideParams
  },
  data() {
    return {
      edit: null,
      selectedNodes: []
    }
  },
  props: {
    recipe: Object,
    recipeLayers: Object,
    customblockList: Array,
    customblockDetail: Object,
    loadingCustomblockDetail: Boolean
  },
  computed: {
    graph: {
      get() {
        return this.edit ?? this.recipe.body
      },
      set(v) {
        this.edit = v
      }
    }
  },
  methods: {
    selectNode(item) {
      this.selectedNodes = item.nodes
    }
  },
  async mounted() {
    this.$nextTick(() => this.$refs.graph.buildGraph())
  }
}
</script>
<style lang="scss" scoped>
.body-wrap {
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr adjustVW(304);
  width: 100%;
  height: 100%;
  background: #fcfcfc;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Ccircle%20cx%3D%221%22%20cy%3D%221%22%20r%3D%221%22%20style%3D%22fill%3A%23e2e2e2%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: space;
  background-position: adjustVW(16);
  background-size: adjustVW(16);
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-main;
  &-active {
    background: #f4f8ff;
  }
}

.graph {
  padding: $space-small;
}

.recipe-param {
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: $box-shadow-main;
  z-index: 1;
}
</style>
