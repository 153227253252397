var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.hiddenParams)?_c('div',{staticClass:"param"},[_c('div',{staticClass:"param-name"},[(!_vm.notEdditingHiddenItem(_vm.name))?[_c('div',{staticClass:"param-name-text-with-icon"},[_c('texts',{staticClass:"param-name-text",attrs:{"size":"small","color":"gray","text":_vm.translatedParamName}}),(_vm.$te('recipe.param.' + _vm.layerName + '.' + _vm.name + 'Help'))?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(
            _vm.$t('recipe.param.' + _vm.layerName + '.' + _vm.name + 'Help')
          ),expression:"\n            $t('recipe.param.' + layerName + '.' + name + 'Help')\n          ",modifiers:{"right":true}}],staticClass:"param-name-text-with-icon-button"},[_c('icons',{attrs:{"iconName":"info","size":"16"}})],1):_vm._e()],1)]:_vm._e()],2),_c('div',{staticClass:"param-body"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.edit)?_c('div',{key:"notEdit"},[(_vm.type === 'select' && _vm.layerName)?_c('div',[(_vm.layerName === 'customblock')?_c('texts',{attrs:{"text":_vm.convertCustomblock(_vm.value)}}):_c('texts',{attrs:{"text":_vm.$t('recipe.param.' + _vm.layerName + '.' + _vm.checkValueEscape(_vm.value))}})],1):(_vm.type === 'bool')?_c('div',[_c('div',{staticClass:"param-checkbox"},[_c('checkbox-base',{attrs:{"checked":_vm.value,"isDisabled":""}})],1)]):(_vm.type === 'list')?_c('div',[_c('param-period',{attrs:{"layerName":_vm.layerName,"name":_vm.name,"edit":_vm.edit,"list_body":_vm.list_body},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1):(_vm.type === 'checkbox')?_c('div',[_c('div',{staticClass:"param-checkbox-not-edditing"},[_vm._l((_vm.value),function(item,index){return [(_vm.layerName === 'customblock')?_c('texts',{key:index,attrs:{"text":_vm.convertCustomblock(item)}}):_c('texts',{key:index,attrs:{"text":item}})]})],2)]):(
            _vm.type === 'listItem' &&
              _vm.$te('recipe.param.' + _vm.layerName + '.' + _vm.value)
          )?_c('div',[_c('texts',{attrs:{"text":_vm.$t('recipe.param.' + _vm.layerName + '.' + _vm.value)}})],1):(_vm.type === 'number_select')?_c('div',[_c('texts',{attrs:{"text":_vm.radioButtonTranslation(_vm.value),"error":_vm.error}})],1):_c('div',[_c('texts',{attrs:{"text":_vm.value,"error":_vm.error}})],1)]):_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.numValueTips),expression:"numValueTips"}],key:"edit"},[(_vm.type === 'number_select')?_c('div',[_c('radio-input-list',{staticClass:"param-radio-input",attrs:{"items":_vm.extra_selections,"translatedNames":_vm.translatedExtraSelectionsName,"tooltipList":_vm.tooltipExtraSelectionsList,"name":_vm.name,"min":_vm.min,"max":_vm.max,"step":_vm.setStep,"defaultNumValue":_vm.radioInputNumValue ? _vm.radioInputNumValue : _vm.min},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1):(_vm.type === 'bool')?_c('div',{staticClass:"param-checkbox"},[_c('checkbox-base',{attrs:{"checked":_vm.value},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1):(_vm.type === 'checkbox')?_c('div',[_c('checkbox-list-recipe',{attrs:{"checked":_vm.value,"values":_vm.options},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1):(_vm.type === 'select')?_c('div',{staticClass:"param-selectbox"},[_c('select-box',{attrs:{"firstSelectItem":{
              name: _vm.options.filter(function (e) { return e.value === _vm.value; })[0].name,
              value: _vm.value
            },"items":_vm.options,"isGray":"","min":"","scrollBar":_vm.scrollBar,"scrollSize":_vm.scrollSize},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1):(_vm.type === 'number' || _vm.type === 'float')?_c('div',[_c('input-box',{ref:"inputBox",attrs:{"type":"number","value":_vm.value,"min":_vm.min,"max":_vm.max,"step":_vm.setStep,"required":!(_vm.defaultValue == null || _vm.defaultValue === ''),"isGray":"","forceStep":"","error":_vm.error},on:{"blur":_vm.resetTips,"input":function($event){return _vm.updateNumInputValue($event)},"change":function($event){return _vm.updateNumValue($event)},"focus":function($event){return _vm.$emit('focus')}}})],1):(_vm.type === 'int')?_c('div',[_c('input-box',{ref:"inputBox",attrs:{"type":"number","value":_vm.value,"min":_vm.min,"max":_vm.max,"step":1,"required":!(_vm.defaultValue == null || _vm.defaultValue === ''),"isGray":"","error":_vm.error},on:{"blur":_vm.resetTips,"input":function($event){return _vm.updateNumInputValue($event, true)},"change":function($event){return _vm.updateNumValue($event, true)},"focus":function($event){return _vm.$emit('focus')}}})],1):(_vm.type === 'list')?_c('div',[_c('param-period',{attrs:{"layerName":_vm.layerName,"name":_vm.name,"edit":_vm.edit,"list_body":_vm.list_body},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1):_c('div',[_c('input-box',{attrs:{"type":_vm.type,"value":_vm.value,"error":_vm.error,"isGray":""},on:{"blur":_vm.onBlur,"focus":function($event){return _vm.$emit('focus')}},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1),_c('transition-toggle-contents',[(_vm.disabledTips)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.disabledTips),expression:"disabledTips"}],staticClass:"disabled-wrap"}):_vm._e()])],1)])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }